<template>
  <div class="home" style="width: 100%" v-loading="loading">
    <div>
      <el-card shadow="hover">
        <template #header>
          <div class="card-header" style="font-weight: bold; color: grey">
            <el-icon><info-filled /></el-icon>
            <span>课程数据</span>
          </div>
        </template>

        <el-descriptions
          class="margin-top"
          title=""
          :column="3"
          size="small"
          border
        >
          <el-descriptions-item width="150px">
            <template #label>
              <div class="cell-item">
                <el-icon><briefcase /></el-icon>
                板块总数
              </div>
            </template>
            {{ formDescription.sections1Count }} 个
          </el-descriptions-item>

          <el-descriptions-item width="150px">
            <template #label>
              <div class="cell-item">
                <el-icon><success-filled /></el-icon>
                已安装板块
              </div>
            </template>
            {{ formDescription.sections1CountAct }} 个
          </el-descriptions-item>

          <el-descriptions-item width="150px">
            <template #label>
              <div class="cell-item">
                <el-icon><list /></el-icon>
                课程总集数
              </div>
            </template>
            {{ formDescription.lessionsCount }} 集
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                <el-icon><success-filled /></el-icon>
                已安装课程数
              </div>
            </template>
            {{ formDescription.lessionsCountAct }} 集
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div class="cell-item">
                <el-icon><histogram /></el-icon>
                搜索词Top10
              </div>
            </template>

            <el-tag
              style="margin-right: 5px; margin-bottom: 5px"
              size="small"
              v-for="item in formDescription?.hotSearchTop"
              :key="item"
              >{{ item.search_words }}</el-tag
            >
          </el-descriptions-item>
        </el-descriptions>
      </el-card>

      <el-card style="margin-top: 20px" shadow="hover">
        <template #header>
          <div class="card-header" style="font-weight: bold; color: grey">
            <el-icon><info-filled /></el-icon><span>版块信息</span>
          </div>
        </template>

        <el-table :data="tableData" style="">
          <el-table-column prop="id" label="id" v-if="false" />
          <el-table-column prop="title" label="版块名称" />
          <el-table-column prop="logo" label="LOGO">
            <template #default="scope">
              <div>
                <img style="height: 30px" :src="scope.row.logo" alt="" />
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="le_total_count" label="课程总数" />
          <el-table-column prop="le_count" label="已安装课程数" />
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import { errorMessage } from "@/utils/message";
import { requestHttp } from "@/network/request";

import {
  Briefcase,
  List,
  SuccessFilled,
  Histogram,
  InfoFilled,
} from "@element-plus/icons-vue";

export default {
  name: "Contents",
  components: { InfoFilled, Briefcase, List, SuccessFilled, Histogram },
  props: {
    msg: String,
  },

  setup() {
    const state = reactive({
      loading: false,
      tableData: [],
      formLabelWidth: "100px",
      formDescription: {},
    });

    //------------------------------------------------------------------------------------------------------------
    // 方法 start
    //------------------------------------------------------------------------------------------------------------

    const getLessions = () => {
      state.loading = true;
      requestHttp(
        {
          url: "/adminGetDataBaseInfo",
        },
        function (res) {
          state.loading = false;
          // debugger
          if (res.data.data.length > 0) {
            state.formDescription = res.data.data[0];
          }
        },
        function (errData) {
          //处理错误信息
          state.loading = false;
          errorMessage(errData.msg);
        }
      );
    };

    const getTableData = () => {
      state.loading = true;
      requestHttp(
        {
          url: "/adminGetDataSections1List",
        },
        function (res) {
          state.loading = false;

          state.tableData = res.data.data;
        },
        function (errData) {
          //处理错误信息
          state.loading = false;
          errorMessage(errData.msg);
        }
      );
    };

    //------------------------------------------------------------------------------------------------------------
    // 方法 end
    //------------------------------------------------------------------------------------------------------------

    //------------------------------------------------------------------------------------------------------------
    // 事件 start
    //------------------------------------------------------------------------------------------------------------

    //------------------------------------------------------------------------------------------------------------
    // 事件 end
    //------------------------------------------------------------------------------------------------------------

    onMounted(() => {
      getLessions();
      getTableData();
    });
    return {
      ...toRefs(state),
      Briefcase,
      List,
      SuccessFilled,
      Histogram,
      InfoFilled,
    };
  },
};
</script>

<style scoped>
div,
span,
p {
  font-size: 15px !important;
}

.cell {
  font-size: 15px !important;
}

.el-input {
  max-width: 200px;
}
</style>
