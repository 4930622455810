<!--
 * @Author: yushuangji
 * @Date: 2022-02-19 16:55:43
 * @Description: 
-->
<template>
  <div ref="refMain">
    <span :style="{ fontSize: fontSize }" class="color-font"
      >欢迎访问{{ title }}</span
    >
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from "vue";
import { getCommonConfig, getSections1Colors } from "@/utils/commonFunc";
export default {
  name: "Welcome",
  props: {
    color: {
      type: String,
      default: "",
    },
    fontSize: {
      type: String,
      default: "",
    },
  },
  setup() {
    const refMain = ref();
    const state = reactive({
      title: "",
    });

    const initData = async () => {
      const a = await getCommonConfig();
      state.title = a.title;
    };

    //获取字体颜色
    const setColors = () => {
      const colors = getSections1Colors();
      // Color_font
      refMain.value.style.setProperty(
        "--Color_font",
        colors.color_welcome_font
      );
    };

    onMounted(() => {
      initData();
      setColors();
    });

    return {
      ...toRefs(state),
      refMain,
    };
  },
};
</script>

<style scoped>
.color-font {
  color: var(--Color_font);
}
</style>

