<template>
  <div v-loading="loading" element-loading-background="rgba(0,0,0,0)">
    <div class="home" :style="{ height: windowHeight + 'px' }" v-if="!isMobile">
      <img
        id="backimg"
        :style="{ width: windowWidth + 'px' }"
        :src="imgUrlPrefix1 + 'login_backimg.jpg'"
      />
      <div class="lf_top_box">
        <img class="lf_logo" :src="imgUrlPrefix1 + 'login_logo.png'" alt="" />
        <div class="lf_login_box">
          <Welcome fontSize="18px" />
          <img
            class="lf_login_exit"
            :src="imgUrlPrefix1 + 'login.png'"
            alt=""
            style="visibility: hidden"
          />
        </div>
      </div>

      <div
        class="lf_middle_box"
        :style="{
          backgroundImage: 'url(' + imgUrlPrefix1 + 'login_box_bg.png' + ')',
        }"
      >
        <div class="lf_login_box_1">用户登录</div>
        <div class="lf_login_box_2">
          <img :src="imgUrlPrefix1 + '2.png'" alt="" /><input
            placeholder="请输入读者证号或有效证件号码"
            type="text"
            v-model="userName"
          />
        </div>
        <div class="lf_login_box_2">
          <img :src="imgUrlPrefix1 + '1.png'" alt="" /><input
            placeholder="请输入密码"
            type="password"
            v-model="userPassword"
          />
        </div>
        <div class="lf_login_box_3">
          <img
            :src="imgUrlPrefix1 + 'login_true.png'"
            alt=""
            @click="clickLogin"
          /><img :src="imgUrlPrefix1 + 'login_cancel.png'" alt="" />
        </div>
      </div>
      <div class="lf_bottom_box">
        <div></div>
        <div class="lf_bottom_middle_box">
          <img :src="imgUrlPrefix1 + 'gslogo.png'" alt="" />
          <div>
            <CopyrightOnlyText />
          </div>
        </div>
        <img class="lf_code_img" :src="imgUrlPrefix1 + 'code.png'" alt="" />
      </div>
    </div>
    <!-- //竖版模式 -->
    <div class="home" v-if="isMobile">
      <img
        id="backimg"
        :style="{ height: windowHeight + 'px' }"
        :src="imgUrlPrefix1 + 'login_backimg.jpg'"
      />
      <div class="lf_top_box lf_top_box_1">
        <div class="lf_login_box">乐儿数字资源平台</div>
      </div>
      <div class="lf_middle_box lf_middle_box_1">
        <div class="lf_login_box_1">用户登录</div>
        <div class="lf_login_box_2">
          <img :src="imgUrlPrefix1 + '2.png'" alt="" /><input
            placeholder="请输入读者证号或有效证件号码"
            type="text"
            @focus="hideBottom"
            @blur="showBottom"
            v-model="userName"
          />
        </div>
        <div class="lf_login_box_2">
          <img :src="imgUrlPrefix1 + '1.png'" alt="" /><input
            placeholder="请输入密码"
            type="password"
            @focus="hideBottom"
            @blur="showBottom"
            v-model="userPassword"
          />
        </div>
        <div class="lf_login_box_3 lf_login_box_3_1">
          <img
            :src="imgUrlPrefix1 + 'login_true_1.png'"
            alt=""
            @click="clickLogin"
          /><img :src="imgUrlPrefix1 + 'login_cancel_1.png'" alt="" />
        </div>
      </div>
      <div v-if="bottomflag" class="lf_bottom_box lf_bottom_box_1">
        <div class="lf_code_gslogo_box">
          <img
            class="lf_gslogo_img"
            :src="imgUrlPrefix1 + 'gslogo_1.png'"
            alt=""
          />
        </div>
        <div class="lf_bottom_middle_box">
          <CopyrightOnlyText :showWrap="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "element-plus/es/components/message/style/css";
import { computed, onMounted, ref } from "vue";
import { requestHttp } from "../network/request";
import { common_isNull } from "../utils/commonFunc";
import { errorMessage, successMessage } from "../utils/message";
import Fotter from "../components/Fotter.vue";
import { getImgUrlPrefix, checkMobile } from "@/utils/commonFunc";
import Welcome from "@/components/Welcome.vue";
import CopyrightOnlyText from "@/components/CopyrightOnlyText.vue";

export default {
  name: "Home",
  components: { errorMessage, Fotter, Welcome, CopyrightOnlyText },
  props: {
    msg: String,
  },
  data() {
    return {
      isMobile: false,
      imgUrlPrefix1: getImgUrlPrefix(),
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度

      userName: "",
      userPassword: "",
      loading: false,
      section1Data: [],
      bottomflag: true,
    };
  },
  mounted() {
    var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
        if (window.fullHeight < window.fullWidth) {
          document.documentElement.style.fontSize =
            window.fullWidth / 8.001 + "px";
        } else {
          document.documentElement.style.fontSize =
            window.fullWidth / 3.125 + "px";
        }
      })();
    };

    //判断是否登录
    let tmpLogin = localStorage.getItem("sshuuid");
    if (tmpLogin) {
      this.getSection1();
    }

    that.isMobile = checkMobile();
  },
  methods: {
    clickLogin() {
      let that = this;

      if (common_isNull(that.userName)) {
        //用户名不存在
        errorMessage("请输入用户名！");
        return;
      } else {
        //密码不存在
        if (common_isNull(that.userPassword)) {
          errorMessage("请输入密码！");
          return;
        }
      }
      that.loading = true;
      //发送验证码
      requestHttp(
        {
          ignoreCheckUserid: true,
          headerParams: {
            username: that.userName,
            password: that.userPassword,
          },
          // url: "/getSection2",
          url: "/userlogin",
        },
        function (dd) {
          that.loading = false;
          successMessage("登录成功");

          localStorage.setItem("sshuuid", dd.data.data.uuid);
          localStorage.setItem("sshname", dd.data.data.user_name);

          that.$router.push("/first");
        },
        function (errData) {
          //处理错误信息
          that.loading = false;
          errorMessage(errData.msg);
        }
      );
    },

    //取一级版块
    getSection1() {
      let that = this;
      if (that.loading) {
        return;
      }

      let tmpUrl = "getSection1";
      let tmpParams = {};

      that.loading = true;
      requestHttp(
        {
          headerParams: tmpParams,
          url: tmpUrl,
        },
        function (res) {
          let tmpData = res.data.data;
          that.section1Data = tmpData;
          that.loading = false;

          if (that.section1Data.list.length > 1) {
            //有多个版块
            that.$router.push({ path: "/First" });
          }
          if (that.section1Data.list.length == 1) {
            //只有一个版块
            // that.$router.push({ path: '/second', query: { sections1Id: that.section1Data.list[0].id } })
            that.$router.push({
              path: "/home",
              query: { sections1Id: that.section1Data.list[0].id },
            });
          }
          if (that.section1Data.list.length < 1) {
            //没有配置版块
            errorMessage("暂无数据！");
          }
        },
        function (errData) {
          //处理错误信息
          that.loading = false;
          errorMessage(errData.msg);
        }
      );
    },
    showBottom() {
      let that = this;
      setTimeout(() => {
        that.bottomflag = true;
      }, 300);
    },

    hideBottom() {
      this.bottomflag = false;
    },
  },
};

computed(() => {
  return {};
});
</script>

<style scoped>
#backimg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}

.lf_top_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lf_logo {
  width: 1.932rem;
  height: 0.254rem;
  margin-left: 0.3rem;
  margin-top: 0.14rem;
}

.lf_login_box {
  display: flex;
  align-items: center;
  font-size: 0.12rem;
  color: #fff;
}

.lf_login_exit {
  width: 0.281rem;
  height: 0.281rem;
  margin: 0 0.2rem;
}

.lf_middle_box {
  display: flex;
  flex-direction: column;
  width: 1.849rem;
  height: 1.329rem;
  background-image: url(../assets/images/login_box_bg.png);
  background-size: cover;
  margin: 1.07rem auto 0;
  justify-content: center;
}

.lf_login_box_1 {
  font-size: 0.126rem;
  text-align: center;
  color: #80b0b2;
  font-weight: bold;
  margin-bottom: 0.09rem;
}

.lf_login_box_2 {
  display: flex;
  align-items: center;
  margin-bottom: 0.09rem;
}

.lf_login_box_2 img {
  width: 0.212rem;
  height: 0.212rem;
  margin: 0 0.08rem 0 0.22rem;
}

.lf_login_box_2 input {
  width: 1.078rem;
  height: 0.185rem;
  border: 2px solid #80b0b2;
  border-radius: 0.1rem;
  text-align: center;
}

.lf_login_box_2 input:focus {
  width: 1.078rem;
  height: 0.185rem;
  border: 2px solid #80b0b2;
  border-radius: 0.1rem;
  outline: none;
}

.lf_login_box_2 input::-webkit-input-placeholder {
  font-size: 0.06rem;
  color: #c5c5c5;
}

.lf_login_box_3 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0.09rem;
}

.lf_login_box_3 img {
  width: 0.643rem;
  height: 0.177rem;
  cursor: pointer;
}

.lf_login_box_3 img:hover {
  transform: scale(1.05);
}

.lf_login_box_3_1 {
  flex-direction: column;
}

.lf_bottom_box {
  height: 0.57rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.056rem;
  color: #b1b1b1;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.lf_bottom_middle_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.lf_bottom_middle_box img {
  width: 1.119rem;
  height: 0.151rem;
  margin-bottom: 0.168rem;
}

.lf_code_img {
  width: 0.399rem;
  height: 0.512rem;
  margin-right: 0.143rem;
  margin-bottom: 0.08rem;
}

/* 竖版 */
.lf_top_box_1 {
  justify-content: center;
  margin-top: 0.12rem;
}

.lf_top_box_1 .lf_login_box {
  color: #cef0f1;
  font-size: 0.136rem;
  justify-content: space-between;
  letter-spacing: 0.04rem;
}

.lf_top_box_1 .lf_login_exit {
  width: 0.375rem;
  height: 0.375rem;
  margin-left: 0.3rem;
  margin-right: 0;
}

.lf_middle_box_1 {
  margin: 0.915rem auto 0 !important;
  align-items: center;
  height: auto !important;
  display: flex;
  flex-direction: column;
  width: 3.126rem;
  height: 1.329rem;
  background-image: unset;
  margin: 1.07rem auto 0;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
}

.lf_middle_box_1 .lf_login_box_1 {
  font-size: 0.253rem;
  text-align: center;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.04rem;
  margin-bottom: 0.22rem;
}

.lf_middle_box_1 .lf_login_box_2 {
  display: flex;
  align-items: center;
  margin-bottom: 0.17rem;
}

.lf_middle_box_1 .lf_login_box_2 img {
  width: 0.423rem;
  height: 0.423rem;
  margin: 0 0.17rem 0 0.056rem;
}

.lf_middle_box_1 .lf_login_box_2 input {
  width: 1.829rem;
  height: 0.37rem;
  border: 2px solid #80b0b2;
  border-radius: 0.2rem;
  font-size: 0.111rem;
  text-align: center;
}

.lf_middle_box_1 .lf_login_box_3 {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.lf_middle_box_1 .lf_login_box_3 img {
  width: 2.439rem;
  height: 0.346rem;
  cursor: pointer;
  margin-bottom: 0.15rem;
}

.lf_logo_1 {
  width: 0.86rem;
  height: 0.414rem;
}

.lf_middle_box_1 .lf_biaoti_img {
  width: 2.446rem;
  height: 0.622rem;
}

.lf_middle_box_1 .lf_enter_img {
  width: 0.846rem;
  height: 0.846rem;
  margin-top: 0.1rem;
}

.lf_enter_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #80b0b2;
  font-size: 0.112rem;
}

.lf_bottom_box_1 {
  height: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.062rem;
  color: #b1b1b1;

  width: 100%;
}
body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.lf_bottom_box_1 .lf_bottom_middle_box {
  font-size: 0.062rem;
  color: #b1b1b1;
}

.lf_code_gslogo_box {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: flex-end;
}

.lf_code_gslogo_box .lf_gslogo_img {
  width: 1.186rem;
  height: 0.161rem;
  margin-right: 0.15rem;
  margin-bottom: 0.1rem;
}
</style>
