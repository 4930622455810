<!--
 * @Author: yushuangji
 * @Date: 2022-02-19 16:55:43
 * @Description: 
-->
<template>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #f78989;
      border-top: 1px solid rgb(230, 230, 230);
    "
  >
    <!-- <span> ICP证：辽ICP备12008249号-3 </span> -->
    <span> 澳通（大连）科技发展有限公司 版权所有 </span>
    <span>AllTop(Dalian) Science and Technology Development Limited</span>
  </div>
</template>

<script>
export default {
  name: "Copyright",
};
</script>

