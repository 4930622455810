<template>
  <div class="lf_content_box" ref="refContent">
    <Header :isMobile="isMobile"></Header>

    <el-image-viewer
      z-index="999999999"
      :hide-on-click-modal="trueValue"
      v-if="showViewer"
      @close="closeViewer"
      :url-list="lessionsData.playListImg.imgList"
      :initial-index="lessionsData.playListImg.showIndex"
      @switch="changePictureIndexCarousel"
    />

    <el-row>
      <el-col :xs="24" :sm="24" :xl="24" class="" v-if="!isMobile">
        <div class="lf_main_box_H5">
          <el-scrollbar style="width: 87%; margin-left: 0.3rem">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                margin-top: 15px;
                margin-bottom: 10px;
              "
            >
              <Sections2 :isMobile="isMobile"></Sections2>
            </div>
          </el-scrollbar>

          <div
            class="lf_main_box_mid"
            v-if="lessionsData.showImgList.length > 0"
          >
            <el-carousel
              class="lf_center_pc"
              style="width: 4rem"
              :arrow="showImgCarouselButton === true ? 'always' : 'never'"
              ref="refCarousel"
              height="2.23rem"
              :autoplay="!trueValue"
              indicator-position="none"
              @change="changePictureIndexCarousel"
              @mousemove="pcMousePassScrollBar()"
              @mouseleave="pcMouseOutScrollBar()"
            >
              <el-carousel-item
                v-for="(item, index) in lessionsData.showImgList"
                :key="index"
                :name="item.title"
              >
                <div class="demo-image__preview">
                  <el-image
                    style="cursor: pointer"
                    title="点击查看大图"
                    @click="clickShowViewer"
                    :src="hostName + item.file_url"
                  >
                    <template #placeholder>
                      <div class="image-slot">
                        加载中<span class="dot">...</span>
                      </div>
                    </template>
                  </el-image>
                </div>
              </el-carousel-item>

              <div
                class="swiper-pagination-custom background"
                style="height: 18px; z-index: 70; display: none"
              >
                <span style="margin: 0 auto; color: white"
                  ><i class="iconfont icon-iconfontzhizuobiaozhun47"></i>
                  点击查看更多精彩图片!</span
                >
              </div>
              <!-- -->
              <div
                v-show="pcImgScrollBar.show"
                class="swiper-pagination-custom"
                style="z-index: 99; justify-content: space-between"
              >
                <div style="z-index: 30">
                  <img
                    :src="imgUrlPrefix1 + 'changePre.png'"
                    @click="changeScrollPre()"
                    style="cursor: pointer; height: 0.488rem"
                    alt=""
                  />
                </div>

                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    z-index: 30;
                  "
                >
                  <img
                    :src="imgUrlPrefix1 + 'changeNext.png'"
                    @click="changeScrollNext()"
                    style="cursor: pointer; height: 0.488rem"
                    alt=""
                  />
                  <div
                    class="lf_look_all ysj_select_all"
                    style="cursor: pointer"
                    @click="pcImgScrollBar.showAll = true"
                  >
                    <img src="@/assets/images/查看全部.png" alt="" />
                  </div>
                </div>

                <div class="swiper-pagination-custom">
                  <el-scrollbar
                    ref="refSwiperPagination"
                    min-size="5"
                    style="
                      margin-left: 40px;
                      margin-right: 0.7rem;
                      height: 100%;
                    "
                  >
                    <div
                      class="lf_min_pic"
                      style="
                        margin-left: 68px;
                        margin-right: 154px;
                        margin-top: 0.07rem;
                      "
                    >
                      <!-- 图片logo列表 -->
                      <span
                        class="swiper-pagination-customs"
                        style=""
                        v-for="(item, index1) in lessionsData.playList"
                        :key="index1"
                      >
                        <img
                          :src="item.logo"
                          @click="changeLession(index1, item)"
                          class="ysj_pointer"
                          :class="{
                            ysj_selected:
                              index1 == pcImgScrollBar.currSelectIndex,
                          }"
                          style="width: 100%"
                        />
                      </span>
                    </div>
                  </el-scrollbar>
                </div>
              </div>
            </el-carousel>

            <div
              class="lf_main_box_mid_video_btn_box_top_middle_box"
              v-if="pcImgScrollBar.showAll"
            >
              <div class="lf_btn_select_video_s_tit">
                <div class="lf_btn_select_video_s_tit_1">选集</div>
                <div
                  class="lf_btn_select_video_s_tit_2 ysj_cursor"
                  @click="pcImgScrollBar.showAll = false"
                >
                  <img :src="imgUrlPrefix1 + 'close.png'" alt="" />
                </div>
              </div>
              <div class="lf_main_box_mid_video_btn_box_top_middle">
                <template
                  v-for="(item, index2) in lessionsData.playList"
                  :key="index2"
                >
                  <div
                    style="height: 0.8rem"
                    @click="changeLession(index2, item)"
                    class="ysj_pointer"
                    :class="{
                      ysj_selected: index2 == pcImgScrollBar.currSelectIndex,
                    }"
                  >
                    <img
                      :src="item.logo"
                      alt=""
                      style="width: 100%; border-radius: 0.09rem"
                    />
                  </div>
                </template>
              </div>
            </div>

            <!-- 选集框 start -->
            <div class="lf_main_box_mid_video_btn_box" style="margin-left: 5px">
              <div class="lf_main_box_mid_video_btn_box_top">
                <div
                  class="lf_main_box_mid_video_btn_box_top_1"
                  @click="changeScroll('left')"
                >
                  <img
                    class="ysj_pointer"
                    :src="imgUrlPrefix1 + 'arrow_left.png'"
                    alt=""
                  />
                </div>
                <el-scrollbar
                  style="height: 35px !important ; line-height: 35px"
                  ref="refSwiperPaginationLessions"
                >
                  <div class="lf_main_box_mid_video_btn_box_top_2">
                    <template
                      v-for="(item, index) in pageSet.pageArr"
                      :key="index"
                    >
                      <div
                        class="ysj_pointer2"
                        @click="changePage(item.num)"
                        :class="{ lf_select: pageSet.curr == item.num }"
                      >
                        {{ item.text }}
                      </div>
                    </template>
                  </div>
                </el-scrollbar>
                <div
                  class="lf_main_box_mid_video_btn_box_top_1"
                  @click="changeScroll('right')"
                >
                  <img
                    class="ysj_pointer"
                    :src="imgUrlPrefix1 + 'arrow_right.png'"
                    alt=""
                  />
                </div>
              </div>
              <div class="lf_main_box_mid_video_btn_box_top_middle">
                <template
                  v-for="(item, index) in lessionsData.playList"
                  :key="index"
                >
                  <div
                    class="ysj_pointer 1112"
                    v-if="
                      index < pageSet.curr * pageSet.size &&
                      index >= (pageSet.curr - 1) * pageSet.size
                    "
                    @click="changeLession(index, item)"
                    :class="{ lf_select: lessionsData.playingIndex == index }"
                  >
                    {{ item.title }}
                  </div>
                </template>
              </div>
            </div>
            <!-- 选集框 end -->
          </div>

          <div class="lf_main_box_bottom">
            <div class="lf_main_box_bottom_1">
              {{ lessionsData.playList[pcImgScrollBar.currSelectIndex]?.title }}
              <i
                v-if="
                  lessionsData.playList[pcImgScrollBar.currSelectIndex]?.subList
                    .length > 0
                "
                title="多图"
                style="margin-left: 20px; color: var(--Color_section2_font)"
                class="iconfont icon-a-ziyuan521"
              ></i>
            </div>
            <div class="lf_main_box_bottom_2">
              <div class="lf_main_box_bottom_2_1">
                {{
                  lessionsData.playList[pcImgScrollBar.currSelectIndex]
                    ?.detail_words
                }}
              </div>
              <!-- <img
                class="lf_main_box_bottom_2_2"
                :src="imgUrlPrefix1 + 'code.png'"
              /> -->
            </div>
          </div>
        </div>
      </el-col>

      <!-- 手机版 -->
      <el-col
        :xs="24"
        :sm="24"
        :xl="24"
        class=""
        style="background-color: DEEEE1; flex-shrink: 0; flex-grow: 1"
        v-if="isMobile"
      >
        <div class="lf_main_box_H5_phone" v-if="true">
          <div class="lf_main_box_mid_phone lf_center_phone">
            <div
              class="swiper-container1"
              v-if="lessionsData?.showImgList.length > 0"
              @touchstart="start($event)"
              @touchmove="move($event)"
            >
              <el-carousel
                :autoplay="!trueValue"
                ref="refCarouselPhone"
                height="1.77rem"
                indicator-position="none"
                :arrow="showImgCarouselButton === true ? 'always' : 'never'"
                @change="changePictureIndexCarousel"
              >
                <el-carousel-item
                  v-for="(item, index) in lessionsData?.showImgList"
                  :key="index"
                >
                  <el-image
                    @click="clickShowViewer"
                    :src="hostName + item.file_url"
                    style="width: 100%"
                  >
                    <template #placeholder>
                      <div class="image-slot">
                        加载中<span class="dot">...</span>
                      </div>
                    </template>
                  </el-image>
                </el-carousel-item>
              </el-carousel>
            </div>

            <div
              class="swiper-pagination-custom"
              style="z-index: 99; justify-content: space-between"
            >
              <div style="z-index: 30">
                <img
                  :src="imgUrlPrefix1 + 'changePrePhone.png'"
                  @click="changeScrollPrePhone()"
                  style="cursor: pointer; height: 0.34rem; margin: 0rem"
                  alt=""
                />
              </div>

              <div style="display: flex; align-items: center; z-index: 30">
                <img
                  :src="imgUrlPrefix1 + 'changeNextPhone.png'"
                  @click="changeScrollNextPhone()"
                  style="cursor: pointer; height: 0.34rem; margin: 0rem"
                  alt=""
                />
              </div>

              <div class="swiper-pagination-custom">
                <el-scrollbar
                  ref="refSwiperPaginationPhone"
                  min-size="5"
                  style="margin-left: 20px; margin-right: 20px; height: 90%"
                >
                  <div class="lf_min_pic" style="">
                    <!-- 图片logo列表 -->
                    <span
                      class="swiper-pagination-customs"
                      style=""
                      v-for="(item, index1) in lessionsData.playList"
                      :key="index1"
                    >
                      <img
                        :src="item.logo"
                        @click="changeLession(index1, item)"
                        class="ysj_cursor"
                        :class="{
                          ysj_selected:
                            index1 == pcImgScrollBar.currSelectIndex,
                        }"
                        style="width: 100%"
                      />
                    </span>
                  </div>
                </el-scrollbar>
              </div>
            </div>

            <div
              @click="mobile.showAler = true"
              style="
                display: flex;
                justify-content: flex-end;
                text-decoration: underline;
                margin: 5px 0.14rem;
                color: var(--Color_section2_font);
                font-size: 0.15rem !important;
              "
            >
              图片列表
            </div>
          </div>
          <div class="lf_main_box_bottom_phone">
            <div class="lf_main_box_bottom_1_phone">
              {{
                lessionsData.playList[phoneImgScrollBar.currSelectIndex]?.title
              }}

              <i
                v-if="
                  lessionsData.playList[phoneImgScrollBar.currSelectIndex]
                    ?.subList.length > 0
                "
                title="多图"
                style="margin-left: 20px; color: var(--Color_section2_font)"
                class="iconfont icon-a-ziyuan521"
                >多图</i
              >
            </div>
            <div class="lf_main_box_bottom_3_phone">
              <view
                class="lf_main_box_bottom_3_1_phone"
                style="font-weight: bold"
                >简介</view
              >
              <view @click="showtext" class="lf_main_box_bottom_3_2_phone"
                >{{ mobile.showtextFlag ? "收起" : "展开"
                }}<img
                  :style="
                    mobile.showtextFlag
                      ? 'transform: rotate(90deg)'
                      : 'transform: rotate(-90deg)'
                  "
                  :src="imgUrlPrefix1 + 'back.png'"
                  alt=""
              /></view>
            </div>
            <div class="lf_main_box_bottom_2_phone">
              <div
                class="lf_main_box_bottom_2_1_phone"
                :class="
                  mobile.showtextFlag ? 'lf_main_box_bottom_2_1_phone_open' : ''
                "
              >
                {{
                  lessionsData.playList[phoneImgScrollBar.currSelectIndex]
                    ?.detail_words
                }}
              </div>
            </div>
          </div>
        </div>

        <!-- 选集 -->
        <div
          class="lf_alert_box"
          @click="mobile.showAler = false"
          v-if="mobile.showAler"
        >
          <div class="lf_main_box_mid_video_btn_box">
            <div class="lf_btn_select_video_s_tit">
              <div class="lf_btn_select_video_s_tit_1">选集</div>
              <div
                class="lf_btn_select_video_s_tit_2"
                @click="mobile.showAler = false"
              >
                <img :src="imgUrlPrefix1 + 'close.png'" alt="" />
              </div>
            </div>

            <div
              class="lf_main_box_mid_video_btn_box_top_middle"
              style="padding-bottom: 67px"
            >
              <template
                v-for="(item, index) in lessionsData.playList"
                :key="index"
              >
                <div
                  :class="{
                    ysj_selected: phoneImgScrollBar.currSelectIndex == index,
                  }"
                  @click.stop="changeLession(index, item)"
                >
                  <img
                    style="
                      width: 1.35rem;
                      height: 0.9rem;
                      border-radius: 0.18rem;
                    "
                    :src="item.logo"
                    alt=""
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- backgroundS="#dfefdf" -->
    <Fotter :loadedData="loadedData" :isMobile="isMobile"></Fotter>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Fotter from "../components/Fotter.vue";
import Sections2 from "./components/Sections2.vue";

import { useRouter } from "vue-router";
import { reactive, toRefs, ref, onMounted, watch } from "vue";
import { requestHttp } from "@/network/request";
import { getLessionsWhere, checkMobile, checkWx } from "@/utils/commonFunc";
import {
  getImgUrlPrefix,
  getSections1Colors,
  setPlayCount,
} from "@/utils/commonFunc";
export default {
  name: "Home",
  components: {
    Header,
    Fotter,
    Sections2,
  },
  props: {
    msg: String,
  },
  setup() {
    const imgUrlPrefix1 = getImgUrlPrefix();

    const router = new useRouter();
    const refCarousel = ref();
    const refCarouselPhone = ref();
    const refContent = ref();

    const state = reactive({
      hostName: window.location.protocol + "//" + window.location.host,
      isMobile: false,
      loadedData: false,
      showViewer: false,
      trueValue: true,
      showImgCarouselButton: false,

      loading: false,
      routerData: {
        searchType: "", //搜索类型：null、wordIndex、searchText
        searchValue: "", //搜索关键字
        lessionId: "", //课程id
        sections2Id: "", //版块2id
        sections1Id: "", //版块1id
      },
      pcImgScrollBar: {
        scrollBar: {
          always: false,
          scrollLeftValue: 0,
        },
        height: "0.493rem",
        show: false,
        showAll: false,
        currSelectIndex: 0,
      },
      phoneImgScrollBar: {
        scrollBar: {
          always: false,
          scrollLeftValue: 0,
        },
        height: "0.493rem",
        show: false,
        showAll: false,
        currSelectIndex: 0,
      },
      lessionsData: {
        showImgList: [], //显示的图片列表，可能 是一张，也可能是多张
        playList: [], //全部的图片数据，
        //预览的图片列表
        playListImg: {
          imgList: [],
          showIndex: 0,
        },
      },
      mobile: {
        showAler: false,
        showtextFlag: false,
      },

      pageSet: {
        curr: 1,
        size: 1,
        page_count: 0,
        pageArr: [
          {
            num: 1,
            text: "1-30",
          },
        ],
      },
      colors: {},
      //滑动图片用 start
      startX: "",
      startY: "",
      moveY: "",
      moveX: "",
      startTime: "",

      startX_Pre: "",
      startY_Pre: "",
      moveY_Pre: "",
      moveX_Pre: "",
      startTime_Pre: "",
      //滑动图片用 end
    });

    //--------------- pc版 start --------------------
    const refSwiperPagination = ref();
    const refSwiperPaginationLessions = ref();

    //手动滚动
    const changeScrollPre = () => {
      state.pcImgScrollBar.scrollBar.scrollLeftValue -= 80;
      if (state.pcImgScrollBar.scrollBar.scrollLeftValue < 0) {
        state.pcImgScrollBar.scrollBar.scrollLeftValue = 0;
      }
      refSwiperPagination.value.setScrollLeft(
        state.pcImgScrollBar.scrollBar.scrollLeftValue
      );
    };
    //手动滚动
    const changeScrollNext = () => {
      state.pcImgScrollBar.scrollBar.scrollLeftValue += 80;

      refSwiperPagination.value.setScrollLeft(
        state.pcImgScrollBar.scrollBar.scrollLeftValue
      );
    };
    const pcMousePassScrollBar = () => {
      state.pcImgScrollBar.show = true;
    };
    const pcMouseOutScrollBar = () => {
      state.pcImgScrollBar.show = false;
    };

    const closeViewer = () => {
      state.showViewer = false;
    };
    const clickShowViewer = () => {
      state.showViewer = true;
    };
    const switchViewer = (index) => {
      refCarousel?.value?.setActiveItem(index);
      refCarouselPhone?.value?.setActiveItem(index);
    };
    const changeScroll = (direction = "") => {
      if (direction == "left") {
        //
        refSwiperPaginationLessions.value.setScrollLeft(0);
      }
      if (direction == "right") {
        //
        refSwiperPaginationLessions.value.setScrollLeft(1000);
      }
    };
    //--------------- pc版 end --------------------

    //--------------- 手机版 start --------------------
    const refSwiperPaginationPhone = ref();
    //手动滚动
    const changeScrollPrePhone = () => {
      state.phoneImgScrollBar.scrollBar.scrollLeftValue -= 80;
      if (state.phoneImgScrollBar.scrollBar.scrollLeftValue < 0) {
        state.phoneImgScrollBar.scrollBar.scrollLeftValue = 0;
      }
      refSwiperPaginationPhone.value.setScrollLeft(
        state.phoneImgScrollBar.scrollBar.scrollLeftValue
      );
    };
    //手动滚动
    const changeScrollNextPhone = () => {
      state.phoneImgScrollBar.scrollBar.scrollLeftValue += 80;
      // debugger;

      refSwiperPaginationPhone.value.setScrollLeft(
        state.phoneImgScrollBar.scrollBar.scrollLeftValue
      );
    };
    const showtext = () => {
      state.mobile.showtextFlag = !state.mobile.showtextFlag;
    };

    //手动滑动图片
    const start = (e) => {
      state.startX = e.touches[0].clientX;
      state.startY = e.touches[0].clientY;
    };
    const move = (e) => {
      state.moveX = e.touches[0].clientX;
      state.moveY = e.touches[0].clientY;
      var nowtime = new Date().getTime();
      if (state.startTime == undefined || nowtime > state.startTime) {
        if (state.startX - state.moveX <= 0) {
          // 右滑触发
          prev();
          return false;
        } else {
          next();
          return false;
        }
      }
    };
    const prev = () => {
      refCarouselPhone.value.prev();
      state.startTime = new Date().getTime() + 500;
    };
    const next = () => {
      refCarouselPhone.value.next();
      state.startTime = new Date().getTime() + 500;
    };

    const startPre = (e) => {
      state.startX_Pre = e.touches[0].clientX;
      state.startY_Pre = e.touches[0].clientY;
    };
    const movePre = (e) => {
      state.moveX_Pre = e.touches[0].clientX;
      state.moveY_Pre = e.touches[0].clientY;
      var nowtime = new Date().getTime();
      if (state.startTime_Pre == undefined || nowtime > state.startTime_Pre) {
        if (state.startX_Pre - state.moveX_Pre <= 0) {
          // 右滑触发
          prevPre();
          return false;
        } else {
          nextPre();
          return false;
        }
      }
    };
    const prevPre = () => {
      if (state.lessionsData.playListImg.showIndex >= 1) {
        state.lessionsData.playListImg.showIndex--;
      }

      state.startTime_Pre = new Date().getTime() + 500;
    };
    const nextPre = () => {
      if (
        state.lessionsData.playListImg.showIndex <=
        state.lessionsData.playListImg.imgList.length - 1
      ) {
        state.lessionsData.playListImg.showIndex++;
      }
      state.startTime_Pre = new Date().getTime() + 500;
    };

    //--------------- 手机版 end --------------------

    //切换绘本
    const changePictureIndexCarousel = (index) => {
      // console.log("---changePictureIndexCarousel", changePictureIndexCarousel);

      state.lessionsData.playListImg.showIndex = index;
      switchViewer(index);
    };

    const initMounted = () => {
      const tmpParams = router.currentRoute.value.query;

      state.routerData.searchType = tmpParams?.searchType;
      state.routerData.searchValue = tmpParams?.searchValue;
      state.routerData.lessionId = tmpParams?.lessionId;
      state.routerData.sections2Id = tmpParams?.sections2Id;
      state.routerData.sections1Id = tmpParams?.sections1Id;

      initData();

      window.onresize = () => {
        return (() => {
          window.fullHeight = document.documentElement.clientHeight;
          window.fullWidth = document.documentElement.clientWidth;
          // that.windowHeight = window.fullHeight; // 高
          // that.windowWidth = window.fullWidth; // 宽
          if (window.fullHeight < window.fullWidth) {
            document.documentElement.style.fontSize =
              window.fullWidth / 8.001 + "px";
          } else {
            document.documentElement.style.fontSize =
              window.fullWidth / 3.125 + "px";
          }
          state.isMobile = checkMobile();
          // window.history.go(0);
        })();
      };

      refContent.value.style.setProperty(
        "--Img_backimg_2",
        `url(${imgUrlPrefix1}/backimg_2.jpg)`
      );

      setColors();

      state.isMobile = checkMobile();
    };

    const initData = async () => {
      const tmprouter = router.currentRoute.value;
      const returnData = await getLessionsWhere(tmprouter);

      state.lessionsData.playList = returnData.data.list;
      state.pageSet.page_count = returnData.data.page_count;
      state.pageSet.size = returnData.data.size;
      state.pageSet.pageArr = makePageArr();

      if (state.lessionsData.playList.length > 0) {
        setTimeout(() => {
          //

          let tmpIndex = 0;
          if (tmprouter.query?.lessionId) {
            state.lessionsData.playList.forEach((item, index) => {
              if (item.id == tmprouter.query?.lessionId) {
                tmpIndex = index;
              }

              // state.lessionsData.playListImg.push(item.logo);
            });
          }

          //设置playListImg，用于预览图片

          // state.lessionsData.playList.forEach((item, index) => {
          //   state.lessionsData.playListImg.push(item.file_url);
          //   if (item?.subList.length > 0) {
          //     item.subList.forEach((item1) => {
          //       state.lessionsData.playListImg.push(item1.file_url);
          //     });
          //   }
          // });

          //

          // state.lessionsData.showImgList = [
          //   state.lessionsData.playList[tmpIndex],
          // ];
          changeLession(tmpIndex, state.lessionsData.playList[tmpIndex]);

          state.loadedData = true;
        }, 1000);
      }
    };

    const setColors = () => {
      const colors = getSections1Colors();
      state.colors = colors;

      refContent.value.style.setProperty(
        "--Color_section2_font",
        colors.color_section2_font
      );

      refContent.value.style.setProperty(
        "--Color_play_resume_font",
        colors.color_play_resume_font
      );
    };

    const makePageArr = () => {
      let tmpArr = [];
      for (let i = 1; i <= state.pageSet.page_count; i++) {
        tmpArr.push({
          num: i,
          text: state.pageSet.size * (i - 1) + 1 + "-" + i * state.pageSet.size,
        });
      }
      return tmpArr;
    };

    const changeLession = (index, item) => {
      //判断是否为外部链接
      if (item.type == "link") {
        window.open(item.link_url);
        return;
      }

      state.pcImgScrollBar.currSelectIndex = index;
      state.phoneImgScrollBar.currSelectIndex = index;
      state.lessionsData.playingIndex = index;

      if (item?.subList.length > 0) {
        state.lessionsData.showImgList = item.subList;
        state.showImgCarouselButton = true;
      } else {
        state.lessionsData.showImgList = [item];
        state.showImgCarouselButton = false;
      }

      //设置预览图
      state.lessionsData.playListImg.imgList = [];
      if (item?.subList.length > 0) {
        item.subList.forEach((item1) => {
          state.lessionsData.playListImg.imgList.push(item1.file_url);
        });
      } else {
        state.lessionsData.playListImg.imgList.push(item.file_url);
      }

      setTimeout(() => {
        switchViewer(0);

        setPlayCount(item.id, "lessions");
      }, 1000);

      state.mobile.showAler = false;
      state.pcImgScrollBar.showAll = false;
    };

    const changePage = (pageNum) => {
      console.log("state", state);
      state.pageSet.curr = pageNum;
    };

    //检查横屏
    const checkScreenDirection = () => {
      window.addEventListener(
        "onorientationchange" in window ? "orientationchange" : "resize",
        function () {
          if (!checkWx()) {
            if (window.orientation === 180 || window.orientation === 0) {
              state.isMobile = checkMobile();
            }
            if (window.orientation === 90 || window.orientation === -90) {
              state.isMobile = checkMobile();
            }
          }
        },
        false
      );
    };

    onMounted(() => {
      initMounted();
      checkScreenDirection();
    });

    watch(
      () => router.currentRoute.value,
      () => {
        initData();
      }
    );

    return {
      ...toRefs(state),
      refSwiperPagination,
      refCarousel,
      refCarouselPhone,
      refSwiperPaginationPhone,
      refContent,
      refSwiperPaginationLessions,

      changeScrollPre,
      changeScrollNext,
      pcMousePassScrollBar,
      pcMouseOutScrollBar,
      changeScrollPrePhone,
      changeScrollNextPhone,
      showtext,
      changePictureIndexCarousel,

      closeViewer,
      clickShowViewer,
      switchViewer,
      imgUrlPrefix1,

      changeLession,
      changeScroll,
      changePage,

      start,
      move,
      prev,
      next,
      startPre,
      movePre,
      prevPre,
      nextPre,
    };
  },
};
</script>

<style scoped>
.lf_content_box {
  background: var(--Img_backimg_2) no-repeat;
  /* background: url(../assets/images/backimg_2.jpg) no-repeat; */
  background-size: 8.001rem 7.432rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.lf_main_box_H5 {
  width: 80%;
  margin: 0 auto 0.648rem;
}

.lf_main_box_top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.1rem 0.3rem;
}

.lf_main_box_top div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Color_play_resume_font);
  font-size: 0.08rem;
  width: 0.7rem;
  word-break: keep-all;
  margin: 0 0.12rem;
}

.lf_main_box_top .lf_select {
  border-bottom: 2px solid var(--Color_section2_font);
  color: var(--Color_section2_font);
}

.lf_main_box_mid {
  width: 6rem;
  /* height: 2.907rem; */
  height: 2.23rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination-custom {
  position: absolute;
  right: 0;
  bottom: 0;
  left: unset;
  width: 4rem;
  /* height: 0.493rem; */
  height: 0.52rem;
  background-color: white; /*var(--Color_section2_font);*/
  display: flex;
  align-items: center;
  border-top-left-radius: 0.16rem;
}

.swiper-button-next {
  background: url(../assets/images/next_large.png) no-repeat;
  background-size: 0.165rem 0.413rem;
  width: 0.165rem;
  height: 0.413rem;
}

.swiper-button-prev {
  background: url(../assets/images/pre_large.png) no-repeat;
  background-size: 0.165rem 0.413rem;
  width: 0.165rem;
  height: 0.413rem;
}

.swiper-pagination-custom img {
  /* width: 0.083rem; */
  height: 0.45rem;
  margin: 0 0.06rem;
}

.lf_look_all {
  width: 0.26rem;
  height: 0.36rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--Color_section2_font);
  padding: 0 0.05rem;
  font-size: 0.1rem;
  font-weight: bold;
  background-color: #fff;
  border-radius: 50%;
  flex-shrink: 0;
  /* margin: 0 0.16rem; */
  margin-right: 10px;
  /* background-image: url("../assets/images/查看全部.png"); */
}

.lf_min_pic {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.swiper-pagination-customs {
  width: 0.6rem;
  /* height: 0.42rem; */
  /* background-color: #eff5f5; */
  margin-right: 0.06rem;
  flex-shrink: 0;
  margin-top: 0.04em;
}

.swiper-pagination-customs img {
  width: 100%;
  height: 100%;
}

.swiper-pagination-customs-active {
  border: 1px solid #ffffff;
}

.lf_main_box_bottom {
  width: 4rem;
  padding-left: 0.3rem;
}

.lf_main_box_bottom_1 {
  color: var(--Color_play_resume_font);
  font-size: 30px;
  font-weight: bold;
  margin: 0.15rem 0;

  display: flex;
  align-items: flex-end;
}

.lf_main_box_bottom_1 img {
  width: 25px;
  /* height: 0.098rem; */
  margin-left: 0.13rem;
}

.lf_main_box_bottom_2 {
  display: flex;
  /* width: 6.544rem; */
  margin: 0 auto;
}

.lf_main_box_bottom_2_1 {
  font-size: 13px;
  margin-right: 0.25rem;
  line-height: 32px;
  width: 4rem;
}

.lf_main_box_bottom_2_2 {
  width: 0.399rem !important;
  height: 0.512rem !important;
}

.lf_main_box_mid_phone {
  width: 3.135rem;
  height: 2.12rem;
  position: relative;
}

.lf_main_box_mid_phone .swiper-pagination-custom {
  position: absolute;
  right: 0;
  bottom: 0;
  left: unset;
  width: 100%;
  height: 0.385rem;
  background-color: #3a3a3a; /*var(--Color_section2_font);*/
  display: flex;
  align-items: center;
  border-top-left-radius: 0;
}

.lf_main_box_mid_phone .swiper-pagination-customs {
  width: 0.49rem;
  height: 0.31rem;
  /* background-color: #eff5f5; */
  margin-right: 0.03rem;
  flex-shrink: 0;
}

.lf_main_box_mid_phone .swiper-pagination-customs img {
  width: 100%;
  height: 100%;
}

.lf_main_box_mid_phone .swiper-pagination-customs-active {
  border: 2px solid #ffffff;
}

.lf_main_box_bottom_1_phone {
  font-size: 0.28rem;
  font-weight: bold;
  margin: 0.26rem 0.14rem;
}

.lf_main_box_bottom_3_phone {
  display: flex;
  margin: 0 0.14rem;
  justify-content: space-between;
}

.lf_main_box_bottom_3_1_phone {
  font-size: 0.173rem;
  color: var(--Color_play_resume_font);
}

.lf_main_box_bottom_3_2_phone {
  font-size: 0.15rem;
  color: var(--Color_section2_font);
  display: flex;
  align-items: center;
  justify-content: center;
}

.lf_main_box_bottom_3_2_phone img {
  width: 0.04rem;
  height: 0.12rem;
  margin: 0 0.06rem;
}

.lf_main_box_bottom_2_1_phone {
  margin: 0.14rem 0.14rem;
  font-size: 0.15rem;
  color: var(--Color_play_resume_font);
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 0.4rem;
}

.lf_main_box_bottom_2_1_phone_open {
  overflow: unset;
  -webkit-line-clamp: unset;
  line-clamp: unset;
  height: auto;
  line-height: 32px;
  margin-bottom: 100px;
}

.swiper-container,
.swiper-container1 {
  width: 100%;
  height: 100%;
}

.lf_main_box_mid_video_btn_box_top_middle_box {
  margin-left: 1.08rem;
  height: 2rem;
  width: 4rem;
  display: flex;
  align-items: center;
  position: absolute;
  flex-direction: column;

  left: 0;
  /* background-color: var(--Color_section2_font); */
  border-top-left-radius: 0.16rem;
  z-index: 666;

  background-color: white;
  box-shadow: 2px 2px 11px 2px grey;
}

.lf_main_box_mid_video_btn_box_top_middle {
  /* width: 3.9rem; */
  /* height: 2rem; */
  max-height: 87%;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.1rem;
}

.lf_main_box_mid_video_btn_box_top_middle div {
  width: 1.5rem;
  /* height: 0.72rem; */
  /* border: 1px solid var(--Color_section2_font); */
  border-radius: 0.09rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #ffffff; */
  color: var(--Color_play_resume_font);
  font-size: 0.066rem;
  margin: 0 0.06rem 0.23rem 0;
  font-weight: 400;
  padding: 0.001rem;
  text-align: center;
}

.lf_main_box_mid_video_btn_box_top_middle .lf_select {
  background-color: var(--Color_section2_font);
  color: #fff;
}
.lf_main_box_mid_video_btn_box_top_middle::-webkit-scrollbar {
  width: 0.06rem;
  border-radius: 0.03rem;
}
.lf_main_box_mid_video_btn_box_top_middle::-webkit-scrollbar-track {
  width: 0.06rem;
  background: #fff;
  border-radius: 0.03rem;
}
.lf_main_box_mid_video_btn_box_top_middle::-webkit-scrollbar-thumb {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: var(--Color_section2_font);
}
.lf_main_box_mid_video_btn_box_top_middle::-webkit-scrollbar-thumb:hover {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: var(--Color_section2_font);
}
.lf_main_box_mid_video_btn_box_top_middle::-webkit-scrollbar-thumb:active {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: var(--Color_section2_font);
}

.lf_btn_select_video_s_tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0.1rem 0;
}

.lf_btn_select_video_s_tit_1 {
  font-size: 17px;
  font-weight: bold;
  color: var(--Color_section2_font);
  margin-left: 0.16rem;
}

.lf_btn_select_video_s_tit_2 {
  font-size: 0.14rem;
  font-weight: 400;
  color: var(--Color_section2_font);
  display: flex;
  align-items: center;
  margin-right: 0.18rem;
}

.lf_btn_select_video_s_tit_2 img {
  position: relative;
  left: 0.1rem;
  width: 17px;
  /* height: 0.22rem; */
}

.ysj_cursor {
  cursor: pointer;
}
.ysj_selected {
  border: solid 2px #f3562a !important;
}

.lf_alert_box {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.29);
  z-index: 999999999;
}
.lf_alert_box .lf_main_box_mid_video_btn_box {
  width: 100%;
  height: 87%;
  background-color: #ffffff;
  border-radius: unset;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top {
  height: 0.36rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_1 {
  width: 0.28rem;
  height: 0.28rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_1 img {
  width: 0.2rem;
  height: 0.2rem;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_2 {
  width: 2.92rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_2::-webkit-scrollbar {
  display: none;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_2 div {
  display: flex;
  align-items: center;
  font-size: 0.12rem;
  margin-right: 0.1rem;
  flex-shrink: 0;
  border-bottom: 1px solid var(--Color_play_resume_font);
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_2 .lf_select {
  border-bottom: 2px solid var(--Color_section2_font);
  font-weight: bold;
  color: var(--Color_section2_font);
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_2 div:nth-last-of-type(1) {
  margin-right: 0rem;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_middle {
  width: 2.91rem;
  /* height:87%; */
  max-height: 87%;
  /* flex-grow: 1; */
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.125rem;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_middle div {
  width: 1.25rem;
  height: 0.9rem;
  /* border: 1px solid var(--Color_section2_font); */
  border-radius: 0.18rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsl(0, 0%, 100%);
  color: var(--Color_play_resume_font);
  font-size: 0.14rem;
  margin: 0 0.05rem 0.05rem 0;
  font-weight: 400;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_middle .lf_select {
  background-color: var(--Color_section2_font);
  color: #fff;
}
.lf_alert_box .lf_btn_select_video_s_tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lf_alert_box .lf_btn_select_video_s_tit_1 {
  font-size: 0.17rem;
  font-weight: bold;
  color: var(--Color_play_resume_font);
  margin-left: 0.16rem;
}
.lf_alert_box .lf_btn_select_video_s_tit_2 {
  font-size: 0.14rem;
  font-weight: 400;
  color: var(--Color_section2_font);
  display: flex;
  align-items: center;
  margin-right: 0.14rem;
}
.lf_alert_box .lf_btn_select_video_s_tit_2 img {
  transform: rotate(180deg);
  margin-left: 0.06rem;
  width: 20px;
  height: 20px;
}

/* 选集框 */

.lf_main_box_mid_video_btn_box {
  /* width: 2.126rem; */
  /* height: 2.9rem; */
  /* margin-left: 5px; */
  width: 1.7rem;
  /* max-width: 1.7rem; */
  height: 2.23rem;
  background-color: #fff;
  border-radius: 0.16rem;
  border: 1px solid var(--Color_section2_font);
}

.lf_main_box_mid_video_btn_box_top {
  height: 0.36rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lf_main_box_mid_video_btn_box_top_1 {
  width: 0.16rem;
  height: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lf_main_box_mid_video_btn_box_top_1 img {
  width: 0.08rem;
  height: 0.08rem;
}

.lf_main_box_mid_video_btn_box_top_2 {
  width: 1.72rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
}
.lf_main_box_mid_video_btn_box_top_2::-webkit-scrollbar {
  display: none;
}
.lf_main_box_mid_video_btn_box_top_2 div {
  display: flex;
  align-items: center;
  font-size: 0.08rem;
  margin-right: 0.1rem;
  flex-shrink: 0;
  /* border-bottom: 1px solid var(--Color_play_resume_font); */
  text-decoration: underline;
}
.lf_main_box_mid_video_btn_box_top_2 .lf_select {
  /* border-bottom: 2px solid var(--Color_section2_font); */
  text-decoration: underline;

  color: var(--Color_section2_font);
  font-weight: bold;
}
.lf_main_box_mid_video_btn_box_top_2 div:nth-last-of-type(1) {
  margin-right: 0rem;
}
.lf_main_box_mid_video_btn_box_top_middle {
  width: 90%;
  /* height: 2.414rem; */
  max-height: 1.5rem;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.125rem;
  justify-content: center;
}
.lf_main_box_mid_video_btn_box_top_middle div {
  /* width: 0.82rem; */
  width: 40%;
  /* width: 130px; */
  height: 0.235rem;
  border: 1px solid var(--Color_section2_font);
  border-radius: 0.09rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: var(--Color_play_resume_font);
  /* font-size: 0.09rem; */
  font-size: 13px;
  margin: 0 0.05rem 0.05rem 0;
  font-weight: 400;
}
.background {
  background-color: var(--Color_section2_font) !important ;
}
@keyframes rotate1 {
  1% {
    transform: rotate(0deg) scale(0.4);
  }

  90% {
    transform: rotate(360deg) scale(0.8);
  }
}
.ysj_select_all:hover {
  animation: rotate1 0.8s;
}

.ysj_select_all {
  transform: scale(0.8);
}
.lf_center_pc {
  position: relative;
}
</style>
<style>
.lf_center_pc .el-image__error {
  width: 4rem;
  height: 2rem;
}
.lf_center_phone .el-image__error {
  width: 3.135rem;
  height: 2.12rem;
}
.image-slot {
  position: fixed;
  top: 48%;
  left: 46%;
  font-size: 15px;
}
.el-image-viewer__mask {
  opacity: 0.99 !important;
}
</style>
