<template>
  <div class="rightDiv">
    <!-- {{ currentMenuItem }} -->
    <div>
      <Contents v-if="currentMenuItem == 'contentManage'"></Contents>
      <DataCaculate v-if="currentMenuItem == 'dataCaculate'"></DataCaculate>
      <Home v-if="currentMenuItem == 'home'"></Home>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { ElButton } from "element-plus";
import Contents from "./components/Contents.vue";
import DataCaculate from "./components/DataCaculate.vue";
import Home from "./components/Home.vue";

export default {
  name: "Left",
  components: { ElButton, Contents, DataCaculate, Home },
  props: {
    currentMenuItem: {
      type: String,
      default: "home",
    },
  },

  setup() {
    return {};
  },
};
</script>

<style scoped>
.rightDiv {
  padding: 10px;
}
</style>
