<!--
 上传文件 点图标选择文件后，将文件对象返回到父组件中，选择完文件后会返回给父组件一个对象文件
-->

<template>
  <div>
    <el-upload
      :limit="limit"
      :on-exceed="limitExceed"
      onclick="(function(e){e.stopPropagation()}(event))"
      :multiple="multiple"
      :file-list="propFileList"
      action=""
      ref="uploadRef"
      class="upload-demo"
      :auto-upload="false"
      :on-change="changeUploadFile"
      :accept="acceptTypes"
      :show-file-list="showFileList"
    >
      <el-button type="success">点击选择文件 </el-button>
    </el-upload>
  </div>
</template>


<script>
import { defineComponent, reactive, toRefs, ref } from "vue";
import { errorMessage } from "@/utils/message";

export default defineComponent({
  name: "uploadFile",
  components: {},
  props: {
    limit: {
      type: [Number],
    },
    showUploadLogo: {
      type: Boolean,
      default: true,
    },
    marginLeft: { type: String, default: "0px" },
    //是否显示上传列表
    showFileList: {
      type: Boolean,
      default: true,
    },
    //限制类型，如：'.jpg,.png,.xls'，默认为空，表示不限制类型
    acceptTypes: {
      type: String,
      default: "",
    },
    //是否多选上传
    multiple: {
      type: Boolean,
      default: false,
    },

    propFileList: {
      //文件列表
      type: Array,
    },
    rowData: Object,
    iconFontSize: {
      type: String,
      default: "15px",
    },
  },

  emits: [
    "emit_SelectedFile",
    "emit_SelectedFiles",
    "emitSelectedFilesWithData",
  ],
  setup(props, ctx) {
    //---- 属性 start ----
    const dataObj = reactive({
      fileData: "",
    });

    const dataHeader = ref({
      header: {
        Authorization: localStorage.getItem("token"),
      },
    });

    const dialogData = ref({
      title: "上传文件",
      dialogHeight: "40%",
      dialogVisible: false,
    });
    //---- 属性 end ----

    //---- 事件 start ----

    const iconFontClickFunc = () => {
      dialogData.value.dialogVisible = true;
    };

    const changeUploadFile = (file, fileList) => {
      ctx.emit("emit_SelectedFiles", fileList);
    };

    //---- 事件 end ----

    //---- 方法 start ----
    const limitExceed = () => {
      errorMessage(`只能上传${props.limit}个文件`);
    };
    //---- 方法 end ----

    return {
      ...toRefs(dataObj),

      //funcs

      dataHeader,

      iconFontClickFunc,
      dialogData,
      changeUploadFile,

      limitExceed,
    };
  },
});
</script>


<style scoped>
</style>
