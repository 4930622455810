<template>
  <!-- v-loading="state.loading"  element-loading-background="rgba(0,0,0,0)" -->

  <div ref="refMainBox" :class="{ main: !isMobile }">
    <Header
      propFrom="second"
      color="black"
      @childSearchKeyWordSections2="getSections2"
      @childSearchIndex="changeSearchIndex"
      @childResetIndex="resetSearchIndex"
      :propBackgroundColor="isMobile ? 'white' : 'rgba(0, 0, 0, 0)'"
      :isMobile="isMobile"
    ></Header>
    <el-row>
      <el-col :xs="24" :sm="24" :xl="24" class="" v-if="!isMobile">
        <div
          :class="{ lf_main_box12: state.sections2Data.length > 6 }"
          class="lf_main_box"
          style="margin-bottom: 50px"
          :style="
            state.sections2Data.length < 6
              ? 'align-items: center;justify-content:space-evenly;'
              : state.sections2Data.length > 10
              ? 'align-items: center;justify-content:space-evenly;'
              : 'align-items: center;'
          "
        >
          <img
            :title="item.title"
            @click="goSection2(item)"
            v-for="item in state.sections2Data"
            :key="item"
            class="lf_main_img ysj_pointer"
            :src="item.logo"
            alt=""
          />

          <div
            style="width: 100%"
            v-if="!state.loading && state.sections2Data <= 0"
          >
            <el-empty :description="state.emptyWord"></el-empty>
          </div>
        </div>
      </el-col>

      <!-- 手机版 -->
      <el-col :xs="24" :sm="24" :xl="24" class="" v-if="isMobile">
        <div class="lf_main_box_phone">
          <img
            :title="item.title"
            @click="goSection2(item)"
            v-for="(item, index) in state.sections2Data"
            :key="item"
            class="lf_main_img ysj_pointer"
            :src="item.logo"
            alt=""
            :style="
              index == state.sections2Data.length - 1
                ? 'margin-bottom:0.8rem'
                : ''
            "
          />
          <div
            style="width: 100%"
            v-if="!state.loading && state.sections2Data <= 0"
          >
            <el-empty :description="state.emptyWord"></el-empty>
          </div>
        </div>
      </el-col>
    </el-row>
    <Fotter
      :isMobile="isMobile"
      :showBackgroundColor="true"
      :loadedData="loadedData"
    ></Fotter>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Fotter from "../components/Fotter.vue";
import { requestHttp } from "../network/request";
import { errorMessage } from "../utils/message";
import { getImgUrlPrefix, checkMobile } from "@/utils/commonFunc";

export default {
  name: "Home",
  components: { Header, Fotter },
  props: {
    msg: String,
  },
  data() {
    return {
      listenrScreenDirection: undefined,
      isMobile: false,
      loadedData: false,
      imgUrlPrefix1: getImgUrlPrefix(),
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      state: {
        loading: false,
        hotData: [],
        sections2Data: [],
        emptyWord: "暂无课程哦~",
      },
    };
  },
  beforeCreate() {
    if (
      document.documentElement.clientWidth <
      document.documentElement.clientHeight
    ) {
      document.querySelector("body").setAttribute("style", "");
    }
  },
  beforeUnmount() {
    document.body.removeAttribute("style");
  },
  methods: {
    //检查横屏
    checkScreenDirection() {
      const that = this;

      that.listenrScreenDirection = window.addEventListener(
        "onorientationchange" in window ? "orientationchange" : "resize",
        function () {
          // !checkWx()
          if (true) {
            if (window.orientation === 180 || window.orientation === 0) {
              if (!that.fullScreenStatus) {
                that.initMounted();
              }
            }
            if (window.orientation === 90 || window.orientation === -90) {
              if (!that.fullScreenStatus) {
                that.initMounted();
              }
            }
          }
        },
        false
      );
    },
    goSection2(item) {
      let tmpPath = item.type;
      if (item.type == "h5") {
        tmpPath = "h5new";
      }
      if (item.type == "pictures") {
        tmpPath = "picture";
      }

      this.$router.push({
        path: tmpPath,
        query: {
          sections1Id: item.sections1_id,
          sections2Id: item.id,
        },
      });
    },

    //header组件触发的首字母事件
    changeSearchIndex(e) {
      console.log("changeSearchIndex", e);
      this.getSections2ByWordIndex(e);
    },
    resetSearchIndex() {
      this.getSections2();
    },
    getSections2ByWordIndex(index) {
      let that = this;
      let tmpSections1Id = that.$route.query["sections1Id"];

      if (!tmpSections1Id) {
        errorMessage("数据错误！");
        that.$router.push("/home");
        return;
      }

      if (!index) {
        return;
      }

      let tmpUrl = "searchByWordIndex";
      let tmpParams = {
        word: index,
        sections1_id: tmpSections1Id,
      };

      that.state.loading = true;
      requestHttp(
        {
          headerParams: tmpParams,
          url: tmpUrl,
        },
        function (res) {
          that.state.loading = false;

          let tmpData = res.data.data;
          that.state.sections2Data = tmpData;
        },
        function (errData) {
          //处理错误信息
          that.state.loading = false;
          errorMessage(errData.msg);
        }
      );
    },

    getSections2(e) {
      let that = this;

      if (!that.$route.query["sections1Id"]) {
        errorMessage("数据错误！");
        that.$router.push("/home");
        return;
      }

      let tmpUrl = "getSection2";
      let tmpParams = {
        parent_id: that.$route.query["sections1Id"],
      };

      if (e) {
        tmpParams["keyword"] = e;
      }

      that.state.loading = true;
      requestHttp(
        {
          headerParams: tmpParams,
          url: tmpUrl,
        },
        function (res) {
          that.state.loading = false;

          let tmpData = res.data.data;
          that.state.sections2Data = tmpData.list;

          that.loadedData = true;
        },
        function (errData) {
          //处理错误信息
          that.state.loading = false;
          errorMessage(errData.msg);
        }
      );
    },
    initMounted() {
      // alert("initMounted");

      var that = this;
      // <!--把window.onresize事件挂在到mounted函数上-->
      window.onresize = () => {
        return (() => {
          window.fullHeight = document.documentElement.clientHeight;
          window.fullWidth = document.documentElement.clientWidth;
          that.windowHeight = window.fullHeight; // 高
          that.windowWidth = window.fullWidth; // 宽
          if (window.fullHeight < window.fullWidth) {
            document.documentElement.style.fontSize =
              window.fullWidth / 8.001 + "px";
          } else {
            document.documentElement.style.fontSize =
              window.fullWidth / 3.125 + "px";
          }

          that.isMobile = checkMobile();
          // window.history.go(0);
        })();
      };

      this.getSections2();

      // debugger;

      that.$refs.refMainBox.style.setProperty(
        "--Img_erji_back",
        `url(${this.imgUrlPrefix1}/erji_back.png)`
      );

      that.$refs.refMainBox.style.setProperty(
        "--Img_erji_back_img",
        `url(${this.imgUrlPrefix1}/erji_back_img.png)`
      );

      document.querySelector("body").setAttribute("style", "color:#666666;");

      this.isMobile = checkMobile();
      if (!this.isMobile) {
        // https://leer.atleer.com/api/public/static/images/14/erji_back_img.png
        document
          .querySelector("body")
          .setAttribute(
            "style",
            `background:url(${this.imgUrlPrefix1}/erji_back.png) no-repeat;background-position-y: 0rem;background-size:100% 120%;`
          );
      } else {
        document
          .querySelector("body")
          .setAttribute(
            "style",
            `background:url(${this.imgUrlPrefix1}/erji_back_img.png) no-repeat;background-position-y: -0.9rem;background-size:100% 120%;`
          );
      }
    },
  },
  mounted() {
    this.initMounted();
    this.checkScreenDirection();
  },
};
</script>

<style scoped>
.main {
  /* background: var(--Img_erji_back) no-repeat; */
  background-size: 100% 100%;
  background-position-y: 0.4rem;
}

.lf_main_box {
  width: 90%;
  margin: 0.488rem auto 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: 2.4rem;
}

.lf_main_box img {
  width: 0.7rem;
  margin: 0 0.04rem;
  flex-shrink: 0;
}

.lf_main_box img:hover {
  transform: scale(1.05);
}

.lf_main_box img:nth-of-type(2n + 1) {
  /* width: 0.997rem; */
  width: 0.997rem;
  height: 1.011;
  position: relative;
  top: -0.8rem;
}

.lf_main_box img:nth-of-type(2n) {
  width: 0.997rem;
  height: 1.011;
  position: relative;
  top: 0.2rem;
}
.lf_main_box img:nth-of-type(n + 5) {
  margin-right: 0;
}
.lf_main_box img:nth-of-type(2n + 7) {
  /* width: 0.997rem; */
  /* width: 0.997rem;
  height: 1.011;
  position: relative;
  top: 0rem; */
}

.lf_main_box12 img {
  width: 0.7rem;
  margin: 0 0.28rem;
  flex-shrink: 0;
}

.lf_main_box12 img:hover {
  transform: scale(1.05);
}

.lf_main_box12 img:nth-of-type(2n + 1) {
  /* width: 0.997rem; */
  width: 0.697rem;
  position: relative;
  top: -0.5rem;
}

.lf_main_box12 img:nth-of-type(2n) {
  width: 0.697rem;
  position: relative;
  top: 0rem;
}
.lf_main_box12 img:nth-of-type(n + 5) {
  margin-right: 0;
}
.lf_main_box12 img:nth-of-type(2n + 7) {
  width: 0.697rem;
  position: relative;
  top: -0.7rem;
}
.lf_main_box12 img:nth-of-type(2n + 8) {
  width: 0.697rem;
  position: relative;
  top: -0.2rem;
}
.lf_main_box_phone {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--Img_erji_back_img) no-repeat;
  /* background: url(../assets/images/erji_back_img.png) no-repeat; */
  /* background-color: #fff; */
  background-size: 3.145rem 6.647rem;
  margin-top: -0.4rem;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-y: scroll;
  /* justify-content: space-evenly; */
}

.lf_main_box_phone img {
  width: 0.997rem;
  height: 1.011rem;
}

.lf_main_box_phone img:nth-of-type(1) {
  width: 0.997rem;
  height: 1.011;
  margin-left: 0.32rem;
  margin-top: 0.6rem;
}

.lf_main_box_phone img:nth-of-type(2) {
  width: 0.997rem;
  height: 1.011;
  margin-top: -0.2rem;
  margin-left: 1.8rem;
}

.lf_main_box_phone img:nth-of-type(3) {
  width: 0.997rem;
  height: 1.011;
  margin-left: 0.32rem;
  margin-top: -0.2rem;
  /* margin-right: 0.65rem; */
}

.lf_main_box_phone img:nth-of-type(4) {
  width: 0.997rem;
  height: 1.011;
  margin-top: -0.2rem;
  margin-left: 1.8rem;
}

.lf_main_box_phone img:nth-of-type(5) {
  width: 0.997rem;
  height: 1.011;
  margin-left: 0.32rem;
  margin-top: -0.2rem;
}

.lf_main_box_phone img:nth-of-type(6) {
  width: 0.997rem;
  height: 1.011;
  margin-top: -0.2rem;
  margin-left: 1.8rem;
}

.lf_main_box_phone img:nth-of-type(7) {
  width: 0.997rem;
  height: 1.011;
  margin-left: 0.32rem;
  margin-top: -0.2rem;
}

.lf_main_box_phone img:nth-of-type(8) {
  width: 0.997rem;
  height: 1.011;
  margin-top: -0.2rem;
  margin-left: 1.8rem;
}

.lf_main_box_phone img:nth-of-type(9) {
  width: 0.997rem;
  height: 1.011;
  margin-left: 0.32rem;
  margin-top: -0.2rem;
}

.lf_main_box_phone img:nth-of-type(10) {
  width: 0.997rem;
  height: 1.011;
  margin-top: -0.2rem;
  margin-left: 1.8rem;
}

.lf_main_box_phone img:nth-of-type(11) {
  width: 0.997rem;
  height: 1.011;
  margin-left: 0.32rem;
  margin-top: -0.2rem;
}

.lf_main_box_phone img:nth-of-type(12) {
  width: 0.997rem;
  height: 1.011;
  margin-top: -0.2rem;
  margin-left: 1.8rem;
}

.lf_main_box_phone img:nth-of-type(13) {
  width: 0.997rem;
  height: 1.011;
  margin-left: 0.32rem;
  margin-top: -0.2rem;
}

.lf_main_box_phone img:nth-of-type(14) {
  width: 0.997rem;
  height: 1.011;
  margin-top: -0.2rem;
  margin-left: 1.8rem;
}

.lf_main_box_phone img:nth-of-type(15) {
  width: 0.997rem;
  height: 1.011;
  margin-left: 0.32rem;
  margin-top: -0.2rem;
}

.lf_main_box_phone img:nth-of-type(16) {
  width: 0.997rem;
  height: 1.011;
  margin-top: -0.2rem;
  margin-left: 1.8rem;
}

.lf_main_box_phone img:nth-of-type(17) {
  width: 0.997rem;
  height: 1.011;
  margin-left: 0.32rem;
  margin-top: -0.2rem;
}

body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: #000000; */

  background: var(--Img_erji_back) no-repeat;
  background-size: 100% 100%;
  background-position-y: 0.1rem;
}
</style>