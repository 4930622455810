<template>
  <div class="home" style="width: 100%" v-loading="loading">
    <div style="display: flex">
      <div>
        <el-select
          style="margin-right: 20px"
          v-model="selectedModel1"
          size="small"
          class="m-2"
          @change="changeSelectModel1"
          placeholder="请选择一级版块"
        >
          <el-option
            v-for="item in selectOptions.model1"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-select
          v-model="selectedModel2"
          class="m-2"
          size="small"
          placeholder="请选择二级版块"
          @change="changeSelectModel2"
        >
          <el-option
            v-for="item in selectOptions.model2"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div style="margin-left: 70px; margin-right: 70px">
        <el-input
          v-model="searchValue"
          style="width: 150px; margin-right: 10px"
          size="small"
          placeholder="查询课程内容"
        />
        <el-button type="primary" @click="getLessions" size="small"
          >查询</el-button
        >
      </div>

      <el-button type="primary" @click="dialogImportVisible = true" size="small"
        >导入数据</el-button
      >
    </div>
    <div style="margin-top: 15px; width: 100%; overflow: auto">
      <el-table
        :data="tableData"
        style="width: 100%"
        :scrollbar-always-on="trueValue"
        @sort-change="sortChange"
      >
        <el-table-column
          width="120"
          fixed
          prop="title"
          sortable="custom"
          label="课程名称"
        />
        <el-table-column prop="logo" label="LOGO">
          <template #default="scope">
            <div>
              <img style="height: 30px" :src="scope.row.logo" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="type" sortable="custom" label="课程类型" />
        <el-table-column prop="s1_title" label="所属一级版块" />
        <el-table-column prop="s2_title" label="所属二级版块" />
        <el-table-column prop="hot_power" sortable="custom" label="热门权重" />

        <!-- 实际管理员 start -->
        <el-table-column
          v-if="adminType == 'root'"
          prop="play_count"
          sortable="custom"
          label-class-name="actualname"
        >
          <template #header>
            <span class="actualname">实际点击量</span>
          </template>
          <template #default="scope">
            <div class="actualname">
              {{ scope.row.play_count }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="adminType == 'root'"
          prop="play_count"
          sortable="custom"
          label=""
          width="240"
        >
          <template #header>
            <span class="actualname">一级版块实际点击量(PC/Wx)</span>
          </template>

          <template #default="scope">
            <div class="actualname">
              {{ scope.row.play_count_s1 }} / {{ scope.row.play_count_wx_s1 }}
            </div>
          </template>
        </el-table-column>

        <!-- 实际管理员 end -->

        <el-table-column prop="" width="120" label="操作" fixed="right">
          <template #default="scope">
            <div>
              <img
                @click="iconCLick(scope.row)"
                :row="scope.row"
                style="width: 20px"
                title="编辑"
                src="@/assets/images/icons/editnew.png"
                alt=""
              />
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        :page-size="pageSet.size"
        background
        layout="prev, pager, next"
        :total="pageSet.total"
        @current-change="changePage"
        @prev-click="changePage"
        @next-click="changePage"
      />
    </div>

    <!-- 修改课程 -->
    <el-dialog v-model="dialogFormVisible" title="修改信息">
      <el-form :model="form" label-width="“200px”">
        <el-form-item label="课程名称" :label-width="formLabelWidth">
          <el-input
            v-model="form.title"
            autocomplete="off"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="热门权重" :label-width="formLabelWidth">
          <el-input
            v-model="form.hot_power"
            autocomplete="off"
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small"
            >取消</el-button
          >
          <el-button type="primary" @click="dialogConfirm" size="small"
            >确认</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 课程导入 -->
    <el-dialog v-model="dialogImportVisible" title="导入课程" width="70%">
      <div><DataImport ref="refDataImport" /></div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogImportVisible = false" size="small"
            >取消</el-button
          >
          <el-button type="primary" @click="dialogConfirmImport" size="small"
            >导入</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { computed, onMounted, ref, reactive, toRefs } from "vue";
import { errorMessage, successMessage } from "@/utils/message";
import { requestHttp } from "@/network/request";
import DataImport from "./datacaculate/DataImport.vue";

import { ElButton } from "element-plus";
export default {
  name: "Contents",
  components: { ElButton, DataImport },
  props: {
    msg: String,
  },

  setup() {
    const refDataImport = ref();
    const state = reactive({
      trueValue: false,
      loading: false,
      tableData: [],
      searchValue: "",
      selectedModel1: "",
      selectedModel2: "",
      selectOptions: {
        model1: [],
        model2: [],
        lessions: [],
        addPlayCount: [
          {
            value: 100,
            label: "100",
          },
          {
            value: 300,
            label: "100",
          },
          {
            value: 500,
            label: "100",
          },
          {
            value: 1000,
            label: "1000",
          },
        ],
      },
      sortValue: {
        sortKey: "",
        sortOrder: "asc", //desc
      },
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "100px",
      dialogImportVisible: false,
      adminType: "",
      pageSet: {
        curr: 1,
        total: 0,
        size: 0,
      },
    });

    //------------------------------------------------------------------------------------------------------------
    // 方法 start
    //------------------------------------------------------------------------------------------------------------

    const getLessions = () => {
      state.loading = true;
      requestHttp(
        {
          headerParams: {
            sortkey: state.sortValue.sortKey,
            order: state.sortValue.sortOrder,
            kw: state.searchValue,
            sections1: state.selectedModel1,
            sections2: state.selectedModel2,
            curr: state.pageSet.curr,
          },
          // url: "/getSection2",
          url: "/adminGetLessions",
        },
        function (res) {
          state.loading = false;
          // debugger
          state.tableData = res.data.data.list;
          state.pageSet.curr = res.data.data.curr;
          state.pageSet.total = res.data.data.total;
          state.pageSet.size = res.data.data.count;

          state.adminType = sessionStorage.getItem("admintype");
        },
        function (errData) {
          //处理错误信息
          state.loading = false;
          errorMessage(errData.msg);
        }
      );
    };

    const getOptionsModel1 = (type = "") => {
      let tmpUrl = "adminGetOptionsMode1";
      let tmpParams = {};
      if (type == "model2") {
        tmpUrl = "adminGetOptionsMode2";
        tmpParams = {
          parentId: state.selectedModel1,
        };
      }
      state.loading = true;
      requestHttp(
        {
          headerParams: tmpParams,
          // url: "/getSection2",
          url: tmpUrl,
        },
        function (res) {
          state.loading = false;
          if (type == "model2") {
            state.selectOptions.model2 = res.data.data;
          } else {
            state.selectOptions.model1 = res.data.data;
          }
        },
        function (errData) {
          //处理错误信息
          state.loading = false;
          errorMessage(errData.msg);
        }
      );
    };
    //------------------------------------------------------------------------------------------------------------
    // 方法 end
    //------------------------------------------------------------------------------------------------------------

    //------------------------------------------------------------------------------------------------------------
    // 事件 start
    //------------------------------------------------------------------------------------------------------------
    const changeSelectModel1 = (e) => {
      getOptionsModel1("model2");
      getLessions();
    };

    const changeSelectModel2 = (e) => {
      getLessions();
    };

    const sortChange = (e) => {
      // console.log('sortchange', e);
      state.sortValue.sortKey = e.prop;
      state.sortValue.sortOrder = e.order == "ascending" ? "asc" : "desc";
      getLessions();
    };

    const iconCLick = (e) => {
      // console.log('e', e);
      state.form = e;
      state.dialogFormVisible = true;
      console.log(
        "%c 🎂 state.dialogFormVisible: ",
        "font-size:20px;background-color: #6EC1C2;color:#fff;",
        state.dialogFormVisible
      );
    };

    const dialogConfirm = () => {
      state.dialogFormVisible = false;

      let tmpUrl = "adminUpdateLession";
      let tmpParams = {
        id: state.form.id,
        title: state.form.title,
        hot_power: state.form.hot_power,
      };
      state.loading = true;
      requestHttp(
        {
          headerParams: tmpParams,
          url: tmpUrl,
        },
        function (res) {
          state.loading = false;
          successMessage("更新成功");
        },
        function (errData) {
          //处理错误信息
          state.loading = false;
          errorMessage(errData.msg);
        }
      );
    };

    //导入数据
    const dialogConfirmImport = () => {
      console.log("refDataImport", refDataImport.value.formData);
      const tmpRef = refDataImport.value.formData;

      const tmpFormData = new FormData();
      tmpFormData.append("file", tmpRef.selectFileData);
      tmpFormData.append("lession_type", tmpRef.lessionType);

      let tmpUrl = "adminImportLessions";
      let tmpParams = tmpFormData;
      state.loading = true;
      requestHttp(
        {
          headerParams: tmpParams,
          url: tmpUrl,
        },
        function (res) {
          state.loading = false;
          successMessage("导入成功");
          state.dialogImportVisible = false;
        },
        function (errData) {
          //处理错误信息
          state.loading = false;
          errorMessage(errData.msg);
        }
      );
    };

    const changePage = (e) => {
      console.log("changePage", e);
      state.pageSet.curr = e;
      getLessions();
    };
    //------------------------------------------------------------------------------------------------------------
    // 事件 end
    //------------------------------------------------------------------------------------------------------------

    onMounted(() => {
      getOptionsModel1();
      getLessions();
    });
    return {
      ...toRefs(state),
      refDataImport,
      changeSelectModel1,
      changeSelectModel2,
      getLessions,
      sortChange,
      iconCLick,
      dialogConfirm,

      changePage,
      dialogConfirmImport,
    };
  },
};
</script>

<style scoped>
div,
span,
p {
  font-size: 15px !important;
}

.cell {
  font-size: 15px !important;
}

.el-input {
  max-width: 200px;
}

.actualname {
  color: rgb(78, 175, 231);
}
</style>
