<template>
  <div style="width: 200px">
    <el-menu default-active="home" class="el-menu-vertical-demo">
      <el-sub-menu index="1">
        <template #title>
          <el-icon>
            <Setting />
          </el-icon>
          <span>基本操作</span>
        </template>
        <el-menu-item index="home" @click="changeMenuItem"
          >基本信息</el-menu-item
        >
        <el-menu-item index="dataCaculate" @click="changeMenuItem"
          >数据统计</el-menu-item
        >
        <el-menu-item
          index="contentManage"
          @click="changeMenuItem"
          v-if="adminType == 'root'"
          >内容管理</el-menu-item
        >
      </el-sub-menu>

      <el-sub-menu index="2">
        <template #title>
          <el-icon>
            <Document />
          </el-icon>
          <span>其他操作</span>
        </template>
      </el-sub-menu>
    </el-menu>
  </div>
</template>

<script>
import {
  computed,
  onMounted,
  ref,
  defineComponent,
  reactive,
  toRefs,
} from "vue";
import { ElButton, ElMenu, ElMenuItem, ElMenuItemGroup } from "element-plus";

import {
  Location,
  Document,
  Menu as IconMenu,
  Setting,
} from "@element-plus/icons-vue";

export default defineComponent({
  components: {
    Location,
    Document,
    Setting,
    ElMenu,
    ElMenuItem,
    ElMenuItemGroup,
  },
  props: {},
  emits: ["childChangeMenuItem"],
  setup(props, ctx) {
    const state = reactive({
      adminType: "",
    });
    onMounted(() => {
      console.log("-----");
      state.adminType = sessionStorage.getItem("admintype");
    });

    const changeMenuItem = (e) => {
      ctx.emit("childChangeMenuItem", e.index);
    };
    return {
      ...toRefs(state),
      changeMenuItem,
    };
  },
});
</script>

<style scoped>
.el-menu-container {
  height: 100%;
}
</style>