<!--
 * @Author: yushuangji
 * @Date: 2022-02-26 14:53:28
 * @Description: 
-->
<template>
<div  class="home" style="width:100%;height:100vh;display:flex;align-items:center;">
    <div style="width:100% ;display:flex;justify-content: space-around;align-items:center;">
        <img id="backimg" :style="{ width: windowWidth + 'px' }" src="@/assets/images/backimglogin.jpg" />

        <div>
            <!-- <img src="@/assets/images/loginbackimg.jpg" alt=""> -->
        </div>

        <div>
            <el-card shadow="hover">

                <template #header>
                    <div class="card-header">

                        <span>
                            <el-icon>
                                <avatar />
                            </el-icon> 欢迎进入后台系统
                        </span>
                    </div>
                </template>

                <el-form ref="formRef" :model="form" label-width="60px">
                    <el-form-item label="用户名">
                        <el-input v-model="form.userName"></el-input>
                    </el-form-item>
                    <el-form-item label="密码">
                        <el-input v-model="form.userPass" show-password type="password"></el-input>
                    </el-form-item>

                    <el-form-item>
                    </el-form-item>

                </el-form>

                <div style="width:100%; text-align:center;">
                    <el-button :disabled="loading" style="width:120px;" type="primary" @click="onSubmit">登录</el-button>

                </div>

            </el-card>
        </div>

    </div>

</div>
</template>

<script>
import { onMounted, reactive, toRefs, } from "vue";
import { ElButton, } from "element-plus";
import { requestHttp } from "@/network/request";
import { errorMessage, successMessage } from "@/utils/message";
import { useRouter } from "vue-router";


import {
    Avatar
} from '@element-plus/icons-vue'

export default {
    name: "Contents",
    components: { ElButton, Avatar },
    props: {
        msg: String,
    },

    setup() {

        const router = useRouter();

        const state = reactive({
            loading: false,

            form: {
                userName: '',
                userPass: ''
            },
            windowWidth: document.documentElement.clientWidth, //实时屏幕宽度

        })

        //------------------------------------------------------------------------------------------------------------
        // 方法 start
        //------------------------------------------------------------------------------------------------------------

        //------------------------------------------------------------------------------------------------------------
        // 方法 end
        //------------------------------------------------------------------------------------------------------------

        //------------------------------------------------------------------------------------------------------------
        // 事件 start
        //------------------------------------------------------------------------------------------------------------
        const onSubmit = () => {

            state.loading   = true; 
             requestHttp({
                    headerParams: {
                        username: state.form.userName,
                        password: state.form.userPass,
                    },
                    // url: "/getSection2",
                    url: "/adminLogin",
                },
                function (dd) {

                    state.loading = false;
                    successMessage("管理员登录成功"); 
                    sessionStorage.setItem('adminuuid',dd.data.data['id']);          
                    sessionStorage.setItem('admintype',dd.data.data['admin_type']);    
                    router.push("/Admin")  
                },
                function (errData) {

                    //处理错误信息
                   state.loading = false;
                    errorMessage(errData.msg);
                }
            );
        }
        //------------------------------------------------------------------------------------------------------------
        // 事件 end
        //------------------------------------------------------------------------------------------------------------

        onMounted(() => {})
        return {
            ...toRefs(state),

            onSubmit,

            Avatar,
        };
    },
};
</script>

<style scoped>
#backimg {
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
}

div,
span,
p {
    font-size: 15px !important;
}

.cell {
    font-size: 15px !important;

}

.el-input {
    max-width: 200px;
}
</style>
