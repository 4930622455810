<template>
  <div class="home" style="width: 100%" v-loading="loading">
    <!-- {{ adminType }} -->
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="设置观看量" name="first" v-if="adminType == 'root'">
        <DataSetting></DataSetting>
      </el-tab-pane>
      <el-tab-pane label="查看观看量" name="second">
        <DataChat></DataChat>
      </el-tab-pane>

      <!-- 第一版块的观看量 -->
      <el-tab-pane
        label="查看观看量(真实)"
        name="third"
        v-if="adminType == 'root'"
      >
        <DataChat realType="real"></DataChat>
      </el-tab-pane>

      <!-- video、h5、picture等资源的观看量 -->
      <el-tab-pane
        label="查看资源观看量(真实)"
        name="fourth"
        v-if="adminType == 'root'"
      >
        <DataChat realType="realLessions"></DataChat>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from "vue";

// import DataSetting from "./datacaculate/DataSetting.vue"
import DataSetting from "@/views/Admin/components/datacaculate/DataSetting.vue";
import DataChat from "./datacaculate/DataChat.vue";

import { ElButton } from "element-plus";
export default {
  name: "Contents",
  components: { ElButton, DataSetting, DataChat },
  props: {
    msg: String,
  },

  setup() {
    const activeName = ref("");

    const state = reactive({
      loading: false,
      tableData: [],
      searchValue: "",
      selectedModel1: "",
      selectedModel2: "",
      selectOptions: {
        model1: [],
        model2: [],
        lessions: [],
      },
      sortValue: {
        sortKey: "",
        sortOrder: "asc", //desc
      },
      form: {},
      dialogFormVisible: false,
      formLabelWidth: "100px",
      adminType: "",
    });

    //------------------------------------------------------------------------------------------------------------
    // 方法 start
    //------------------------------------------------------------------------------------------------------------

    //------------------------------------------------------------------------------------------------------------
    // 方法 end
    //------------------------------------------------------------------------------------------------------------

    //------------------------------------------------------------------------------------------------------------
    // 事件 start
    //------------------------------------------------------------------------------------------------------------

    const iconCLick = (e) => {
      console.log("e", e);
      state.form = e;
      state.dialogFormVisible = true;
    };

    //------------------------------------------------------------------------------------------------------------
    // 事件 end
    //------------------------------------------------------------------------------------------------------------

    onMounted(() => {
      state.adminType = sessionStorage.getItem("admintype");
      activeName.value = "second";
      if (state.adminType == "root") {
        activeName.value = "first";
      }
    });
    return {
      ...toRefs(state),
      iconCLick,
      activeName,
    };
  },
};
</script>

<style scoped>
div,
span,
p {
  font-size: 15px !important;
}

.cell {
  font-size: 15px !important;
}

.el-input {
  max-width: 200px;
}
</style>
