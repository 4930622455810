/*
 * @Author: yushuangji
 * @Date: 2022-02-16 21:28:22
 * @Description: 
 */
import axios from 'axios';
import router from '../router/index'


let baseURL = '/api/public/index.php/';//测试版的路径,发版
// let baseURL = '/public/index.php/';//正式版的路径
import { common_isNull } from "@/utils/commonFunc"



/**
 * 
 * @param {*} config {
 *      ignoreCheckUserid：true/false   //是否忽略对user为空的判断，默认为false，仅限前台用户
 *      url:'',
 *      headerParams:{},                //请求参数
 *      showLoading:true/false,         //是否显示loading,
 * }
 * @param {*} successFunc 
 * @param {*} errorFunc 
 */
export async function requestHttp(config, successFunc, errorFunc) {



    //发送网络请求  

    //0.添加通用变量

    //1.安全方面
    // let secretKey = 'loveweb';
    // let randStr = uniqueString();
    // let signature = md5(JSON.stringify(config.headerParams) + secretKey + randStr);//生成签名
    let signature = '';
    let randStr = '';
    let secretKey = 'loveweb';
    //2.请求方面


    let method = 'post';
    let timeout = 500000;

    //3.loading        
    // let loadingObj = '';
    if (typeof (config.showLoading) == undefined) {
        config.showLoading = false;
    }

    if (config.showLoading) {
        console.log('show loading');
    }

    const postConfig = {
        method,
        baseURL,
        timeout,
        url: config.url,
        headers: {
            signature,
            randStr: randStr,
            appkey: secretKey,
            userid: localStorage.getItem('userid')
        }
    }


    //4. 校验用户登录状态
    //4.1 取得发送的请求类型，是发送给管理后台，还是前台用户
    let haveAdmin = false;
    if (config.url.indexOf('admin') >= 0) {
        haveAdmin = true;
    }

    //获取配置文件
    const configValue = await getColors(postConfig);

    //4.1 如果是前台用户user登录，判断localStorage的sshuuid
    if (haveAdmin === false) {
        if (common_isNull(configValue.login_enabled)) {
            config.ignoreCheckUserid = true;//忽略登录
        }

        if (!config.ignoreCheckUserid) {
            if (!localStorage.getItem('sshuuid')) {
                //跳转登录页面
                router.push("/Login")
                return;
            }
        }
    }
    //4.2 如果是后台用户admin登录，判断sessionStorage的adminuuid
    else {
        if (!sessionStorage.getItem('adminuuid')) {
            //跳转登录页面
            router.push("/adminlogin");
        }
    }



    //4.发送请求
    axios.post(
        config.url,
        config.headerParams,
        postConfig
    )
        .then(async function (res) {
            console.log('success', res)
            await getColors(postConfig, haveAdmin);

            if (res.data.code != '200') {
                //业务错误
                console.log('wrong');
                errorFunc(res.data);
                return;
            }

            successFunc(res);
        })
        .catch(function (err) {

            errorFunc(err);

            return;



        })





}


// -------- 辅助函数 start ----------
const getColors = (obj, isAdmin = false) => {
    const config = sessionStorage.getItem('sections1Colors');
    if (config) {
        return new Promise((re) => {
            re(JSON.parse(config));
        })
    }

    return new Promise((re, rj) => {
        const { method,
            baseURL,
            timeout, } = obj;

        axios.post(
            '/getSection1Colors',
            {
                showPre: !(isAdmin || false)
            },
            {
                method,
                baseURL,
                timeout,
                url: '/getSection1Colors'
            }
        )
            .then(function (res) {
                sessionStorage.setItem('sections1Colors', JSON.stringify(res.data.data['colors']));
                sessionStorage.setItem('commonconfig', JSON.stringify(res.data.data['config'][0]));
                re(JSON.parse(sessionStorage.getItem('commonconfig')));
            })
            .catch(function (err) {
                console.log('---error', err);
                rj(false)
            })
    });


}


// -------- 辅助函数 end ----------

