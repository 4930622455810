<template>
  <div class="home" style="width: 100%" v-loading="loading">
    <div style="margin-top: 15px">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="id" v-if="false" />
        <el-table-column prop="title" sortable="custom" label="版块名称" />
        <el-table-column prop="logo" label="LOGO">
          <template #default="scope">
            <div>
              <img style="height: 30px" :src="scope.row.logo" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="type" sortable="custom" label="类型" />
        <el-table-column prop="play_count_min" label="最小观看量" />
        <el-table-column prop="play_count_max" label="最大观看量" />

        <el-table-column prop="play_count_min_wx" label="wx最小观看量" />
        <el-table-column prop="play_count_max_wx" label="wx最大观看量" />

        <!-- 实际管理员 start -->
        <el-table-column
          v-if="adminType == 'root'"
          prop="play_count"
          label="实际点击量"
        >
          <template #header>
            <span class="actualname">实际点击量</span>
          </template>
          <template #default="scope">
            <div class="actualname">
              {{ scope.row.play_count }}
            </div>
          </template>
        </el-table-column>
        <!-- 实际管理员 end -->
        <!-- <el-table-column prop="type" sortable="custom" label="类型" /> -->

        <el-table-column prop="" label="操作">
          <template #default="scope">
            <div>
              <img
                @click="iconCLick(scope.row)"
                :row="scope.row"
                style="width: 20px"
                title="编辑"
                src="@/assets/images/icons/editnew.png"
                alt=""
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog v-model="dialogFormVisible" title="生成观看量">
      <el-form :model="form" label-width="“200px”">
        <el-form-item label="数据源：" :label-width="formLabelWidth">
          <el-radio-group v-model="dataType" size="small">
            <el-radio-button label="wx">微信端数据</el-radio-button>
            <el-radio-button label="pc">PC端数据</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="周期：" :label-width="formLabelWidth">
          <div>
            <el-radio v-model="form.timeType" label="range" size="large"
              >区间</el-radio
            >

            <el-date-picker
              size="small"
              v-model="form.datepicker"
              value-format="YYYY-MM-DD"
              type="daterange"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
            >
            </el-date-picker>

            <p>
              <el-radio v-model="form.timeType" label="1" size="large"
                >一年：</el-radio
              >
            </p>
            <p>
              <el-radio v-model="form.timeType" label="2" size="large"
                >两年：</el-radio
              >
            </p>
          </div>
        </el-form-item>

        <el-form-item label="观看量：" :label-width="formLabelWidth">
          <el-input
            placeholder="最小值"
            v-model="form.play_count_min"
            autocomplete="off"
            size="small"
            style="width: 80px"
          ></el-input>
          ~
          <el-input
            style="width: 80px"
            placeholder="最大值"
            v-model="form.play_count_max"
            autocomplete="off"
            size="small"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small"
            >取消</el-button
          >
          <el-button type="primary" @click="dialogConfirm" size="small"
            >确认</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from "vue";
import { errorMessage, successMessage } from "@/utils/message";
import { requestHttp } from "@/network/request";

import { ElButton } from "element-plus";
export default {
  name: "Contents",
  components: { ElButton },
  props: {
    msg: String,
  },

  setup() {
    const activeName = ref("first");

    const state = reactive({
      loading: false,
      tableData: [],
      searchValue: "",
      dataType: "pc",

      sortValue: {
        sortKey: "",
        sortOrder: "asc", //desc
      },
      form: {
        timeType: "1",
        datepicker: "",
        play_count_max: 0,
        play_count_min: 0,
      },
      dialogFormVisible: false,
      formLabelWidth: "100px",
      selectedRow: {},
      adminType: "",
    });

    //------------------------------------------------------------------------------------------------------------
    // 方法 start
    //------------------------------------------------------------------------------------------------------------

    const getOptionsModel1 = () => {
      let tmpUrl = "adminGetOptionsMode1";
      let tmpParams = {};

      state.loading = true;
      requestHttp(
        {
          headerParams: tmpParams,
          // url: "/getSection2",
          url: tmpUrl,
        },
        function (res) {
          state.loading = false;
          state.tableData = res.data.data;

          state.adminType = sessionStorage.getItem("admintype");
        },
        function (errData) {
          //处理错误信息
          state.loading = false;
          errorMessage(errData.msg);
        }
      );
    };
    //------------------------------------------------------------------------------------------------------------
    // 方法 end
    //------------------------------------------------------------------------------------------------------------

    //------------------------------------------------------------------------------------------------------------
    // 事件 start
    //------------------------------------------------------------------------------------------------------------

    const iconCLick = (e) => {
      state.selectedRow = e;
      state.dialogFormVisible = true;
    };

    const dialogConfirm = () => {
      if (!state.form.play_count_max || !state.form.play_count_min) {
        errorMessage("请输入播放量");
        return;
      }

      state.dialogFormVisible = false;

      debugger;

      let tmpUrl = "adminMakeSystemPlayCount";
      let tmpParams = {
        sections1_id: state.selectedRow?.id,
        type: state.form.timeType,
        start: state.form.datepicker ? state.form.datepicker[0] : "",
        end: state.form.datepicker ? state.form.datepicker[1] : "",
        play_count_max: state.form.play_count_max,
        play_count_min: state.form.play_count_min,
        dataType: state.dataType,
      };
      state.loading = true;
      requestHttp(
        {
          headerParams: tmpParams,
          url: tmpUrl,
        },
        function () {
          state.loading = false;
          successMessage("更新成功");
        },
        function (errData) {
          //处理错误信息
          state.loading = false;
          errorMessage(errData.msg);
        }
      );
    };
    //------------------------------------------------------------------------------------------------------------
    // 事件 end
    //------------------------------------------------------------------------------------------------------------

    onMounted(() => {
      getOptionsModel1();
    });
    return {
      ...toRefs(state),
      iconCLick,
      activeName,
      dialogConfirm,
    };
  },
};
</script>

<style scoped>
div,
span,
p {
  font-size: 15px !important;
}

.cell {
  font-size: 15px !important;
}

.el-input {
  max-width: 200px;
}

.actualname {
  color: rgb(78, 175, 231);
}
</style>
