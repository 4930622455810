<!--
 * @Author: yushuangji
 * @Date: 2022-02-22 07:53:17
 * @Description: 
-->
<template>
  <div class="home" style="width: 100%" v-loading="loading">
    <div style="padding: 10px">
      <div style="margin-bottom: 15px; font-weight: bold">
        <span style="color: #409eff; font-size: 18px !important">{{
          title
        }}</span
        ><span style="font-size: 18px !important">乐儿平台访问统计</span>
      </div>

      <el-card shadow="hover">
        <div style="display: flex; justify-content: space-between">
          <el-radio-group
            v-model="dataType"
            size="small"
            @change="changeDatepicker()"
          >
            <el-radio-button label="wx">查看微信端数据</el-radio-button>
            <el-radio-button label="pc">查看PC端数据</el-radio-button>
          </el-radio-group>

          <el-button
            @click="adminExportExcel"
            type="primary"
            size="small"
            :icon="Download"
          >
            导出
          </el-button>
        </div>

        <div
          style="
            margin-top: 25px;
            display: flex;
            justify-content: space-between;
          "
        >
          <span>
            版块：
            <el-select
              :title="selectedModel1"
              style="margin-right: 20px"
              v-model="selectedModel1"
              size="small"
              class="m-2"
              @change="changeSelectModel1"
              placeholder="请选择一级版块"
            >
              <el-option label="全部版块" value="all" />
              <el-option
                v-for="item in selectOptions.model1"
                :key="item.id"
                :value="item.id"
                :label="item.title"
              >
                <template #default>
                  <el-tooltip :content="item.title" placement="top">
                    <span>{{ item.title }}</span>
                  </el-tooltip>
                </template>
              </el-option>
            </el-select>
          </span>

          <span>
            <el-radio
              v-model="form.timeType"
              @change="changeRadio"
              label="1"
              size="large"
              >一年</el-radio
            >

            <el-radio
              v-model="form.timeType"
              @change="changeRadio"
              label="2"
              size="large"
              >两年</el-radio
            >

            <el-radio
              v-model="form.timeType"
              @change="changeRadio"
              label="range"
              size="large"
              >区间</el-radio
            >

            <el-date-picker
              @change="changeDatepicker"
              :disabled="form.timeType != 'range'"
              value-format="YYYY-MM-DD"
              size="small"
              v-model="form.datepicker"
              type="daterange"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
              :disabled-date="setDisabledDate"
            >
            </el-date-picker>
          </span>
        </div>
      </el-card>
      <!-- 图形 -->
      <div style="margin-top: 20px">
        <el-card shadow="hover">
          <div
            style="display: flex; justify-content: left; align-items: center"
          >
            <el-radio-group
              v-model="chartType"
              @change="chnageChartType"
              style=""
            >
              <el-radio label="line">折线图</el-radio>
              <el-radio label="bar">柱状图</el-radio>
              <el-radio label="pie">饼图</el-radio>
            </el-radio-group>

            <div style="margin-left: 50px">
              <el-link type="primary"
                >统计区间：{{ charts.time.start }} ~{{ charts.time.end }}
              </el-link>
            </div>
            <div style="margin-left: 50px">
              <el-link type="primary">统计总数：{{ charts.total }} </el-link>
            </div>
          </div>

          <div
            :id="'chartccc' + realType"
            :style="{
              width: charts.options.legend.width,
              height: charts.options.legend.height,
            }"
          ></div>
          <div>
            <!-- <Copyright /> -->
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from "vue";
import { errorMessage } from "@/utils/message";
import { requestHttp } from "@/network/request";
import {
  common_formatDate,
  getDomain,
  getCommonConfig,
} from "@/utils/commonFunc";
import * as echarts from "echarts";
// import Copyright from "@/components/Copyright.vue";
import { Download } from "@element-plus/icons-vue";
import { stringifyQuery } from "vue-router";

export default {
  name: "Contents",
  components: {},
  props: {
    msg: String,
    realType: {
      type: String,
      default: "", //空或real
    },
  },

  setup(props) {
    const chartType = ref("line");

    const state = reactive({
      title: "",
      loading: false,
      tableData: [],
      searchValue: "",
      dataType: "pc", //wx,pc

      sortValue: {
        sortKey: "",
        sortOrder: "asc", //desc
      },
      form: {
        timeType: "1",
        datepicker: [],
        play_count: 0,
      },
      dialogFormVisible: false,
      formLabelWidth: "100px",
      selectedModel1: "",
      selectOptions: {
        model1: [],
      },
      charts: {
        objInstance: {},
        options: {
          legend: {
            width: "1000px",
            height: "400px",
          },
          tooltip: {
            trigger: "item",
            
          },
          xAxis: {
            type: "category",
            data: [],
            //data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [],
              //data: [150, 230, 224, 218, 135, 147, 260],
              type: chartType,
            },
          ],
        },
        chartsId: "datachat",
        time: {}, //统计区间
        total: 0, //统计总数
      },
    });

    //------------------------------------------------------------------------------------------------------------
    // 方法 start
    //------------------------------------------------------------------------------------------------------------

    const getOptionsModel1 = () => {
      let tmpUrl = "adminGetOptionsMode1";
      let tmpParams = {};

      state.loading = true;
      requestHttp(
        {
          headerParams: tmpParams,
          // url: "/getSection2",
          url: tmpUrl,
        },
        function (res) {
          state.loading = false;
          state.selectOptions.model1 = res.data.data;
        },
        function (errData) {
          //处理错误信息
          state.loading = false;
          errorMessage(errData.msg);
        }
      );
    };

    const getSystemPlayCount = () => {
      if (!state.selectedModel1) {
        errorMessage("请选择版块");
        return;
      }

      let tmpUrl = "adminGetSystemPlayCount";
      let tmpParams = {
        sections1_id: state.selectedModel1,
        type: state.form.timeType,
        chartType: chartType.value,
        start: state.form.timeType == "range" ? state.form.datepicker[0] : "",
        end: state.form.timeType == "range" ? state.form.datepicker[1] : "",
        dataType: state.dataType,
        realType: props.realType,
      };
      state.loading = true;
      requestHttp(
        {
          headerParams: tmpParams,
          url: tmpUrl,
        },
        function (res) {
          state.charts.time = res.data.data["time"];
          state.charts.total = res.data.data["total"];

          let tmpData = res.data.data["list"];
          state.charts.options.xAxis.data = tmpData["x"];
          state.charts.options.series[0].data = tmpData["y"];
          state.charts.objInstance.setOption(state.charts.options);

          state.loading = false;
        },
        function (errData) {
          //处理错误信息
          state.loading = false;
          errorMessage(errData.msg);
        }
      );
    };

    //设置禁用的日期
    const setDisabledDate = (pickerDate) => {
      return pickerDate > new Date();
    };

    const initData = async () => {
      const a = await getCommonConfig();
      state.title = a.title;
    };
    //------------------------------------------------------------------------------------------------------------
    // 方法 end
    //------------------------------------------------------------------------------------------------------------

    //------------------------------------------------------------------------------------------------------------
    // 事件 start
    //------------------------------------------------------------------------------------------------------------
    const changeDatepicker = () => {
      getSystemPlayCount();
    };
    const changeSelectModel1 = () => {
      //
      getSystemPlayCount();
    };
    const changeRadio = (e) => {
      state.form.timeType = e;
      getSystemPlayCount();
    };

    const chnageChartType = () => {
      getSystemPlayCount();
    };

    const adminExportExcel = async () => {
      const tmpDomain = await getDomain();
      const tmpUrl = tmpDomain + "/api/public/index.php/adminExportExcel";
      // window.open();

      const tmpParams = {
        sections1_id: state.selectedModel1,
        type: state.form.timeType,
        chartType: chartType.value,
        start: state.form.timeType == "range" ? state.form.datepicker[0] : "",
        end: state.form.timeType == "range" ? state.form.datepicker[1] : "",
        dataType: state.dataType,
        realType: props.realType,
      };

      window.open(tmpUrl + "?" + stringifyQuery(tmpParams));
    };
    //------------------------------------------------------------------------------------------------------------
    // 事件 end
    //------------------------------------------------------------------------------------------------------------

    const echartsObj = echarts;
    onMounted(() => {
      initData();
      getOptionsModel1();

      const chartsId = document.getElementById(`chartccc${props.realType}`);
      state.charts.objInstance = echartsObj.init(chartsId);

      state.form.datepicker = [
        common_formatDate(new Date()),
        common_formatDate(new Date()),
      ];
    });
    return {
      ...toRefs(state),
      changeRadio,
      changeSelectModel1,
      changeDatepicker,
      chnageChartType,
      adminExportExcel,
      setDisabledDate,

      chartType,
      Download,
    };
  },
};
</script>

<style scoped>
div,
span,
p {
  font-size: 15px !important;
}

.cell {
  font-size: 15px !important;
}

.el-input {
  max-width: 200px;
}
</style>
