<template>
  <div
    ref="refMain"
    class="lf_header_box"
    :style="{
      backgroundColor: propBackgroundColor ? propBackgroundColor : false,
    }"
  >
    <el-row>
      <el-col
        :xs="24"
        :sm="6"
        :md="6"
        :lg="6"
        :xl="6"
        class=""
        v-if="!isMobile"
      >
        <img
          class="lf_logo_img"
          :src="imgUrlPrefix1 + 'logo.png'"
          @click="goHome"
          style="cursor: pointer"
          alt=""
        />
      </el-col>

      <el-col
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        class=""
        v-if="!isMobile"
        style="
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
        "
      >
        <div
          style="font-size: 30px"
          class=""
          :class="{
            lf_header_right_text_black: color == 'black',
            lf_header_right_text: color == 'white',
          }"
        >
          <Welcome fontSize="20px" />
        </div>
      </el-col>

      <el-col
        :xs="24"
        :sm="6"
        :md="6"
        :lg="6"
        :xl="6"
        class=""
        v-if="!isMobile"
      >
        <div class="lf_header_right_box">
          <div class="lf_header_right_input_box">
            <input type="text" v-model="searchWord" />
            <img
              class="ysj_pointer"
              :src="imgUrlPrefix1 + 'search.png'"
              @click="search"
              alt=""
            />
            <div class="lf_hot_play">
              <div class="lf_hot_play1 ysj_title_color">热门播放:</div>
              <div
                class="lf_hot_play2"
                :class="{ ysj_text_color: color == 'white' }"
              >
                <span
                  :title="item.title"
                  style="width: 100%"
                  v-for="(item, index) in hotLessions"
                  :key="item"
                  @click="goLessions(item)"
                  class="ysj_pointer"
                >
                  {{ item.title }}
                  <span v-if="index < hotLessions.length - 1"> , </span>
                </span>
              </div>
            </div>
          </div>
          <img
            v-if="configValue.login_enabled == '1'"
            class="lf_login_exit ysj_pointer"
            :src="imgUrlPrefix1 + 'exit.png'"
            @click="exit"
            alt=""
          />
        </div>
      </el-col>

      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="24"
        :xl="24"
        class=""
        style="height: 0.3rem"
        v-if="!isMobile"
      >
        <!-- height: 0.788rem -->
        <img
          class="lf_jiansuo_img"
          :src="imgUrlPrefix1 + 'jiansuo.png'"
          alt=""
        />
        <div
          class="lf_jiansuo_li_box"
          :style="{ top: propFrom == 'second' ? '0.1rem' : '0.25rem' }"
        >
          <div class="lf_jiansuo_li_box_first">乐儿索引</div>
          <!-- lf_jiansuo_li_box_select -->
          <div
            @click="setSearchIndex(item)"
            v-for="item in searchIndex.words"
            :key="item"
            class="ysj_pointer"
            :class="{
              lf_jiansuo_li_box_select: item == searchIndex.selectedWord,
            }"
          >
            <span>{{ item }}</span>
          </div>

          <div
            @click="resetSearchIndex"
            class="ysj_pointer lf_jiansuo_li_box_last"
          >
            重置
          </div>
        </div>
      </el-col>

      <!-- 手机版 -->
      <el-col
        :xs="24"
        class=""
        style="display: flex; align-items: center"
        v-if="isMobile"
      >
        <img
          class="lf_back_img_phone"
          :src="imgUrlPrefix1 + 'back.png'"
          @click="goBack()"
          alt=""
        />
        <div class="lf_tit_top_phone" @click="goHome" style="cursor: pointer">
          <p class="lf_tit_top_cn_phone">乐儿数字资源平台</p>
          <p class="lf_tit_top_en_phone">
            Leer Wissdom Education Resources <br />Sharing Platform
          </p>
        </div>
        <img
          class="lf_search_img_phone"
          v-if="!showsearch"
          v-on:click="SearchShow"
          :src="imgUrlPrefix1 + 'search_1.png'"
          alt=""
        />
        <img
          class="lf_shouqi_img_phone"
          v-if="showsearch"
          v-on:click="SearchHide"
          :src="imgUrlPrefix1 + 'shouqi.png'"
          alt=""
        />
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :xl="24"
        :style="showsearch ? 'height:2.8rem;' : 'heigt:0.4rem;'"
        class="lf_search_con_box"
        ref="refPhone"
        v-if="isMobile"
      >
        <div class="lf_header_right_box_phone" style="background-color: white">
          <div class="lf_header_right_input_box_phone">
            <input type="text" v-model="searchWord" />
            <img
              :src="imgUrlPrefix1 + 'search_phone.png'"
              @click="search"
              alt=""
            />
          </div>
          <div class="lf_hot_play_phone">
            <div
              class="lf_hot_play1"
              :class="{ ysj_title_color_phone: color == 'white' }"
            >
              热门播放:
            </div>
            <div
              class="lf_hot_play2"
              :class="{ ysj_text_color_phone: color == 'white' }"
            >
              <span
                style="width: 100%; font-size: 0.118rem !important"
                v-for="(item, index) in hotLessions"
                :key="item"
                @click="goLessions(item)"
              >
                {{ item.title }}

                <span v-if="index < hotLessions.length - 1"> , </span>
              </span>
            </div>
          </div>
        </div>

        <div style="width: 100%; background-color: white; height: 100%">
          <div class="lf_jiansuo_li_box_phone">
            <div class="lf_jiansuo_li_box_first_phone">乐儿索引</div>
            <!-- lf_jiansuo_li_box_select_phone -->
            <div
              v-for="item in searchIndex.words"
              :key="item"
              :class="{
                lf_jiansuo_li_box_select_phone:
                  item == searchIndex.selectedWord,
              }"
            >
              <span @click="setSearchIndex(item)">{{ item }}</span>
            </div>
            <div @click="resetSearchIndex" class="lf_jiansuo_li_box_last_phone">
              重置
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { requestHttp } from "@/network/request";
import { errorMessage } from "@/utils/message";
import { makeUrl } from "@/utils/commonFunc";
import {
  getImgUrlPrefix,
  getSections1Colors,
  getCommonConfigValue,
} from "@/utils/commonFunc";
import Welcome from "@/components/Welcome.vue";

export default {
  name: "Header",
  components: { Welcome },
  props: {
    color: {
      type: String,
      default: "white", //white,c67f45
    },
    propBackgroundColor: {
      type: String,
      default: "c67f45",
    },
    propFrom: {
      type: String,
      default: "",
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      configValue: getCommonConfigValue(),
      imgUrlPrefix1: getImgUrlPrefix(),
      showsearch: false,
      searchIndex: {
        words: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ],
        selectedWord: "",
      },
      searchWord: "",
      hotLessions: {},
    };
  },
  methods: {
    goHome() {
      this.$router.push("/first");
    },
    SearchShow() {
      this.showsearch = true;
    },
    SearchHide() {
      this.showsearch = false;
    },
    resetSearchIndex() {
      let that = this;
      this.searchIndex.selectedWord = "";

      this.$router.push({
        path: "/second",
        query: { sections1Id: that.$route.query["sections1Id"] },
      });

      this.$emit("childResetIndex");
    },
    setSearchIndex(e) {
      this.searchIndex.selectedWord = e;
      this.searchIndexWord(e);
      //   this.$emit("childSearchIndex", e);
    },
    exit() {
      localStorage.removeItem("sshuuid");
      window.location.reload();
    },
    getHot() {
      let that = this;

      let tmpUrl = "getHotLession";
      let tmpParams = {
        sections1id: that.$route.query["sections1Id"],
      };

      requestHttp(
        {
          headerParams: tmpParams,
          url: tmpUrl,
        },
        function (res) {
          let tmpData = res.data.data;
          that.hotLessions = tmpData;
        },
        function (errData) {
          //处理错误信息
          errorMessage(errData.msg);
        }
      );
    },
    searchIndexWord(indexWord) {
      //获取数据
      let that = this;

      let tmpUrl = "searchLessionsByWordIndex";
      let tmpParams = {
        word: indexWord,
        sections1id: that.$route.query["sections1Id"],
      };

      requestHttp(
        {
          headerParams: tmpParams,
          url: tmpUrl,
        },
        function (res) {
          let tmpData = res.data.data.list;
          //   debugger

          if (tmpData.length > 0) {
            let tmpData1 = tmpData[0];
            const tmpParams = makeUrl(tmpData1, "indexWord", indexWord);

            that.$router.push({
              path: tmpParams.path,
              query: tmpParams.query,
            });
          } else {
            errorMessage("该版块内没有搜索到相关内容哦！");
            return;
          }
        },
        function (errData) {
          //处理错误信息
          errorMessage(errData.msg);
        }
      );

      //   this.$emit("childSearchKeyWordSections2", this.searchWord);
    },
    search() {
      //获取数据
      let that = this;

      let tmpUrl = "getLessions";
      let tmpParams = {
        keyword: that.searchWord,
      };

      requestHttp(
        {
          headerParams: tmpParams,
          url: tmpUrl,
        },
        function (res) {
          let tmpData = res.data.data;
          //   debugger
          if (tmpData?.list.length > 0) {
            let tmpData1 = tmpData.list[0];

            const tmpParams = makeUrl(tmpData1, "keyWord", that.searchWord);

            that.$router.push({
              path: tmpParams.path,
              query: tmpParams.query,
            });
          } else {
            errorMessage("没有搜索到相关内容哦！");
            return;
          }
        },
        function (errData) {
          //处理错误信息
          errorMessage(errData.msg);
        }
      );

      //   this.$emit("childSearchKeyWordSections2", this.searchWord);
    },
    goLessions(item) {
      const tmpParams = makeUrl(item);

      this.$router.push({
        path: tmpParams.path,
        query: tmpParams.query,
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    setColors() {
      const colors = getSections1Colors();

      this.$refs.refMain.style.setProperty(
        "--Color_section2_font",
        colors.color_section2_font
      );
      this.$refs.refMain.style.setProperty(
        "--Color_play_resume_font",
        colors.color_play_resume_font
      );
    },
  },
  mounted() {
    let that = this;

    this.getHot();

    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
        if (window.fullHeight < window.fullWidth) {
          document.documentElement.style.fontSize =
            window.fullWidth / 8.001 + "px";
        } else {
          document.documentElement.style.fontSize =
            window.fullWidth / 3.125 + "px";
        }
      })();
    };
    // debugger;

    setTimeout(() => {
      if (that.$refs.refPhone && that.isMobile) {
        that.$refs.refPhone.$el.style.setProperty(
          "--Img_search_backimg",
          `url(${this.imgUrlPrefix1}/search_backimg.png)`
        );
      }
    }, 1000);

    this.setColors();

    this.searchIndex.selectedWord = this.$route.query["searchValue"];
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.lf_logo_img {
  width: 1.932rem;
  height: 0.254rem;
  display: flex;
  margin: 0.116rem 0 0 0.2rem;
}

.lf_header_right_box {
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 0.116rem 10px 0 0;
}

.lf_header_right_text {
  font-size: 0.119rem;
  color: #ffffff;
}

.lf_header_right_text_black {
  font-size: 0.119rem;
  color: var(--Color_section2_font);
}

.lf_header_right_input_box {
  width: 0.945rem;
  height: 0.172rem;
  position: relative;
  margin-left: 0.254rem;
  margin-right: 0.12rem;
  position: relative;
}

.lf_header_right_input_box img {
  /* width: 0.165rem;
  height: 0.164rem;
  position: absolute;
  right: -2px;
  top: 0px; */

  width: 0.07rem;
  height: 0.07rem;
  position: absolute;
  right: 5px;
  top: 0.05rem;
}

.lf_header_right_input_box input {
  width: 0.945rem;
  height: 0.16rem;
  border: 1px solid var(--Color_section2_font);
  border-radius: 0.1rem;
  /* text-align: center; */
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.lf_header_right_input_box input:focus {
  width: 0.945rem;
  height: 0.16rem;
  border: 1px solid var(--Color_section2_font);
  border-radius: 0.1rem;
  outline: none;
}

.lf_header_right_input_box input::-webkit-input-placeholder {
  font-size: 0.06rem;
  color: #c5c5c5;
}

.lf_hot_play {
  width: 0.945rem;
  display: flex;
  height: 0.12rem;
  margin: 0 auto;
  position: absolute;
  right: 0;
  bottom: -0.12rem;
  z-index: 10000;
}

.lf_hot_play .lf_hot_play1 {
  color: var(--Color_play_resume_font);
  font-size: 0.062rem;
  min-width: 65px;
  line-height: 0.12rem;
  overflow: hidden;
}

.lf_hot_play .lf_hot_play2 {
  color: var(--Color_section2_font);
  font-size: 0.062rem;
  flex-grow: 1;
  overflow: hidden;
  line-height: 0.12rem;
}

.lf_login_exit {
  /* width: 0.185rem; */
  height: 0.14rem;
}
.lf_login_exit:hover {
  transform: scale(1.05);
}

.lf_back_img_phone {
  width: 0.14rem;
  height: 0.282rem;
  margin: 0 0 0 0.22rem;
}

.lf_search_img_phone {
  width: 0.3rem;
  height: 0.3rem;
  margin-right: 0.16rem;
}

.lf_shouqi_img_phone {
  width: 0.277rem;
  height: 0.121rem;
  margin-right: 0.16rem;
}

.lf_tit_top_phone {
  font-size: 0.173rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.15rem auto 0.1rem;
}

.lf_tit_top_en_phone {
  font-size: 0.083rem;
  text-align: center;
}

.lf_jiansuo_img {
  position: absolute;
  width: 100%;
  /* width: 7.9rem; */
  /* height: 0.788rem; */
}

.lf_jiansuo_li_box {
  display: flex;
  align-items: center;
  margin-left: 0.315rem;
  position: relative;
  /* top: 32px; */
  top: 0.25rem;
  /* height: 0.788rem; */
  height: 0.3rem;
  z-index: 10000;
}

.lf_jiansuo_li_box_second {
  display: flex;
  align-items: center;
  margin-left: 0.315rem;
  position: relative;
  /* top: 32px; */
  top: 0.15rem;
  /* height: 0.788rem; */
  height: 0.3rem;
  z-index: 10000;
}

.lf_jiansuo_li_box div {
  width: 0.12rem;
  height: 0.12rem;
  border: 1px solid var(--Color_section2_font);
  color: var(--Color_section2_font);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.06rem;
  margin-right: 0.02rem;
  flex-shrink: 0;
}

.lf_jiansuo_li_box div:hover {
  transform: scale(1.1);
}

.lf_jiansuo_li_box .lf_jiansuo_li_box_select {
  width: 0.12rem;
  height: 0.12rem;
  border: 1px solid var(--Color_section2_font);
  color: #fff;
  background-color: var(--Color_section2_font);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.06rem;
  margin-right: 0.02rem;
  flex-shrink: 0;
}

.lf_jiansuo_li_box .lf_jiansuo_li_box_first {
  border: none;
  font-weight: bold;
  width: 0.464rem;
  font-size: 0.08rem;
}

.lf_jiansuo_li_box .lf_jiansuo_li_box_first:hover {
  transform: scale(1);
}

.lf_jiansuo_li_box .lf_jiansuo_li_box_last {
  font-weight: bold;
  width: 0.22rem;
  font-size: 0.08rem;
  border-radius: 0.06rem;
}

.lf_header_right_input_box_phone {
  width: 2.758rem;
  height: 0.372rem;
  position: relative;
  margin: 0 auto;
}

.lf_header_right_input_box_phone img {
  width: 0.2rem;
  height: 0.2rem;
  position: absolute;
  right: 6px;
  top: 10px;
}

.lf_header_right_input_box_phone input {
  width: 2.758rem;
  height: 0.372rem;
  border: 1px solid var(--Color_section2_font);
  border-radius: 0.186rem;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
}

.lf_hot_play_phone {
  width: 2.5rem;
  display: flex;
  height: 0.22rem;
  margin: 0 auto;
}

.lf_hot_play1 {
  color: var(--Color_play_resume_font);
  font-size: 0.118rem;
  /* width: 0.6rem; */
  line-height: 0.22rem;
  overflow: hidden;
}

.lf_hot_play2 {
  color: var(--Color_section2_font);
  font-size: 0.118rem;
  flex-grow: 1;
  overflow: hidden;
  line-height: 0.22rem;
}

.lf_jiansuo_li_box_phone {
  display: flex;
  align-items: center;
  margin-left: 0.19rem;
  position: relative;
  width: 2.758rem;
  flex-wrap: wrap;
  justify-content: center;
}

.lf_jiansuo_li_box_phone div {
  width: 0.232rem;
  height: 0.324rem;
  color: var(--Color_section2_font);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.12rem;
  margin: 0 0.02rem;
  flex-shrink: 0;
  text-align: center;
  font-weight: bold;
}

.lf_jiansuo_li_box_phone div:nth-of-type(n + 12) {
  margin: 0 0.07rem;
  width: 0.15rem;
}

.lf_jiansuo_li_box_phone .lf_jiansuo_li_box_select {
  width: 0.12rem;
  height: 0.12rem;
  border: 1px solid var(--Color_section2_font);
  color: var(--Color_section2_font);
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.12rem;
  margin-right: 0.02rem;
  flex-shrink: 0;
}

.lf_jiansuo_li_box_phone .lf_jiansuo_li_box_first_phone {
  border: none;
  font-weight: bold;
  width: 2.6rem;
  font-size: 0.148rem;
  display: flex;
  color: var(--Color_play_resume_font);
  justify-content: flex-start;
}

.lf_jiansuo_li_box_phone .lf_jiansuo_li_box_last_phone {
  font-weight: bold;
  width: 0.3rem !important;
  font-size: 0.12rem;
}

.lf_search_con_box {
  background: var(--Img_search_backimg) no-repeat;
  background-size: 3.145rem 0.919rem;
  padding-top: 0.4rem;
  height: 0.4rem;
  overflow: hidden;
  width: 100%;
}

.ysj_title_color {
  color: white !important;
}

.ysj_text_color {
  color: var(--Color_section2_font) !important;
}

.ysj_title_color_phone {
  color: rgb(0, 0, 0) !important;
}

.ysj_text_color_phone {
  color: var(--Color_section2_font) !important;
}
.lf_tit_top_cn_phone,
.lf_tit_top_en_phone {
  /* font-size:18px !important; */
  color: var(--Color_section2_font) !important;
}
.lf_tit_top_cn_phone {
  font-size: 18px !important;
}

.ysj_pointer:hover {
  transform: scale(1.05);
}
</style>
