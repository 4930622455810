<template>
  <el-row>
    <el-col class="footer" v-if="!isMobile">
      <div
        class="lf_bottom_box"
        ref="refBottomBox"
        :style="{
          position: fixedFlag,
        }"
      >
        <div></div>
        <div class="lf_bottom_middle_box">
          <a href="http://www.alltopdesign.com/" target="_blank"
            ><img
              class="lf_gslogo_img ysj_pointer"
              :src="imgUrlPrefix1 + 'gslogo.png'"
              alt=""
          /></a>

          <div>
            <CopyrightOnlyText />
          </div>
        </div>
        <img
          class="lf_code_img"
          style="visibility: hidden"
          :src="imgUrlPrefix1 + 'code.png'"
          alt=""
        />
      </div>
    </el-col>

    <!-- 手机版 -->
    <el-col :xs="24" :xl="24" class="" v-if="isMobile">
      <div
        style=""
        ref="refBottomBox1"
        class="lf_bottom_box lf_bottom_box_1 aaaa"
        :style="{
          backgroundColor: backgroundS,
          position: fixedFlag,
        }"
      >
        <!-- {{ fixedFlag }} -->
        <div
          class="lf_code_gslogo_box"
          style="display: flex; justify-content: center"
        >
          <!-- <img class="lf_code_img" :src="imgUrlPrefix1 + 'code_1.png'" alt="" /> -->
          <img
            class="lf_gslogo_img ysj_pointer"
            :src="imgUrlPrefix1 + 'gslogo_1.png'"
            alt=""
          />
        </div>
        <!-- position: fixed; bottom: 5px; font-size: 0.062rem !important -->
        <div class="lf_bottom_middle_box" style="font-size: 10px !important">
          <CopyrightOnlyText />
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {
  toRefs,
  computed,
  onMounted,
  watch,
  defineComponent,
  reactive,
  ref,
} from "vue";
import { getImgUrlPrefix } from "@/utils/commonFunc";
import { useRouter } from "vue-router";
import CopyrightOnlyText from "@/components/CopyrightOnlyText.vue";
export default defineComponent({
  name: "Fotter",
  components: { CopyrightOnlyText },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    loadedData: {
      type: Boolean,
      default: false,
    },
    backgroundS: {
      type: String,
      default: "rgba(255,255,255,0)",
    },
  },

  setup(props) {
    const imgUrlPrefix1 = getImgUrlPrefix();
    const router = new useRouter();
    const refBottomBox = ref();
    const refBottomBox1 = ref();

    const state = reactive({
      fixedFlag: "fixed",
    });

    const reSetFixed = () => {
      setTimeout(() => {
        // alert(
        //   "mounted" + document.body.scrollHeight + "," + window.innerHeight
        // );

        // console.log(router.path, "=--=path", router.currentRoute.value.path);

        const tmpPath = router.currentRoute.value.path;

        if (tmpPath == "/first" || tmpPath == "/") {
          state.fixedFlag = "fixed";

          if (document.body.scrollHeight > window.innerHeight) {
            state.fixedFlag = "";
          }
        } else {
          if (tmpPath == "/second") {
            if (document.body.scrollHeight > window.innerHeight) {
              // alert(1);
              state.fixedFlag = "";
              if (props.isMobile) {
                state.fixedFlag = "fixed";
              }
            } else {
              state.fixedFlag = "fixed";
            }
          } else {
            if (
              tmpPath == "/Video" ||
              tmpPath == "/H5new" ||
              tmpPath == "/Picture"
            ) {
              if (document.body.scrollHeight >= window.innerHeight) {
                // alert(document.body.scrollHeight + "," + window.innerHeight);
                state.fixedFlag = "";
              }
            } else {
              if (document.body.scrollHeight > window.innerHeight) {
                // alert(document.body.scrollHeight + "," + window.innerHeight);
                state.fixedFlag = "";
              }
            }
          }
        }
      }, 1000);
    };
    onMounted(() => {
      setTimeout(() => {
        if (!props.isMobile) {
          refBottomBox.value.style.setProperty(
            "--Img_bottom_line",
            `url(${imgUrlPrefix1}/bottom_line.png)`
          );
        }

        if (props.isMobile) {
          refBottomBox1.value.style.setProperty(
            "--Img_jiansuo_phone",
            `url(${imgUrlPrefix1}/jiansuo_phone.png)`
          );
        }
      }, 2000);
    });

    watch(
      () => props.loadedData,
      () => {
        reSetFixed();
      }
    );

    return {
      ...toRefs(state),
      imgUrlPrefix1,
      refBottomBox,
      refBottomBox1,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lf_bottom_box {
  height: 0.684rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 0.056rem;
  color: #606060;
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
  background: var(--Img_bottom_line);
  background-size: 8.001rem 0.684rem;
  z-index: 999;
}

.lf_bottom_middle_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.075rem;
}

.lf_bottom_middle_box img {
  width: 1.119rem;
  height: 0.151rem;
  margin-bottom: 0.08rem;
}

.lf_code_img {
  width: 0.304rem;
  height: 0.393rem;
  margin-right: 0.138rem;
  margin-bottom: 0.06rem;
}
.lf_bottom_box_1 {
  height: unset;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.062rem;
  color: #606060;
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.8rem;
  background: var(--Img_jiansuo_phone);

  /* background-color: #dfefdf; */
  background-size: 3.145rem 0.949rem;
}

.lf_bottom_box_1 .lf_bottom_middle_box {
  font-size: 0.062rem;
  color: #606060;
  margin-top: 0.06rem;
}

.lf_code_gslogo_box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}

.lf_code_gslogo_box .lf_code_img {
  width: 0.395rem;
  height: 0.488rem;
  margin-left: 0.13rem;
}

.lf_code_gslogo_box .lf_gslogo_img {
  width: 1.186rem;
  height: 0.161rem;
  margin-right: 0.15rem;
}

.lf_enter_img:hover,
.lf_login_exit:hover {
  transform: scale(1.05);
  cursor: pointer;
}
</style>
