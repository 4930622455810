<!--
 * @Author: yushuangji
 * @Date: 2022-02-19 16:55:43
 * @Description: 
-->
<template>
  <!-- <span> ICP证：辽ICP备12008249号-3 </span> -->
  <span> {{ title }}</span>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { getCommonConfig } from "@/utils/commonFunc";

export default defineComponent({
  name: "",
  components: {},
  props: {
    showWrap: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      title: "",
    });

    const initData = async () => {
      const a = await getCommonConfig();

      if (props.showWrap) {
        state.title = a.copyright_wrap;
      } else {
        state.title = a.copyright;
      }
    };

    onMounted(() => {
      initData();
    });

    return {
      ...toRefs(state),
    };
  },
});
</script>

