<template>
  <div ref="refMain">
    <div class="home" v-if="windowWidth > windowHeight">
      <img
        id="backimg"
        :style="{ width: windowWidth + 'px' }"
        :src="imgUrlPrefix1 + 'backimg.jpg'"
      />
      <div class="lf_top_box">
        <img class="lf_logo" :src="imgUrlPrefix1 + 'logo.png'" alt="" />
        <div class="lf_login_box" style="margin-right: 60px">
          <Welcome fontSize="20px" />
          <img
            class="lf_login_exit"
            :src="imgUrlPrefix1 + 'login.png'"
            alt=""
            v-if="configValue.login_enabled == '1' && !haveLogin"
          />
          <img
            class="lf_login_exit"
            :src="imgUrlPrefix1 + 'exit.png'"
            alt=""
            v-if="configValue.login_enabled == '1' && haveLogin"
            @click="quit"
          />
        </div>
      </div>
      <div
        class="lf_middle_box"
        style="right: 0px; position: absolute; margin-right: 1.5rem"
      >
        <img class="lf_biaoti_img" :src="imgUrlPrefix1 + 'biaoti.png'" alt="" />
        <img
          class="lf_enter_img"
          :src="imgUrlPrefix1 + 'enter.png'"
          alt=""
          @click="getSection1"
        />
      </div>
      <div class="lf_bottom_box">
        <div></div>
        <div class="lf_bottom_middle_box">
          <img :src="imgUrlPrefix1 + 'gslogo.png'" alt="" />
          <div>
            <CopyrightOnlyText />
          </div>
        </div>
        <img class="lf_code_img" :src="imgUrlPrefix1 + 'code.png'" alt="" />
      </div>
    </div>
    <!-- //竖版模式 -->
    <div class="home" v-if="windowWidth < windowHeight">
      <img
        id="backimg"
        :style="{ width: windowWidth + 'px' }"
        :src="imgUrlPrefix1 + 'backimg_1.jpg'"
      />
      <div class="lf_top_box lf_top_box_1">
        <div class="lf_login_box" style="margin-left: 10px">
          <Welcome fontSize="0.15rem" />

          <img
            class="lf_login_exit"
            :src="imgUrlPrefix1 + 'login.png'"
            alt=""
            v-if="configValue.login_enabled == '1' && !haveLogin"
          />
          <img
            class="lf_login_exit"
            :src="imgUrlPrefix1 + 'exit_phone.png'"
            alt=""
            v-if="configValue.login_enabled == '1' && haveLogin"
            @click="quit"
          />
        </div>
      </div>
      <div class="lf_middle_box lf_middle_box_1">
        <!-- <img class="lf_logo_1" :src="imgUrlPrefix1 + 'logo_1.png'" alt="" /> -->
        <img
          class="lf_biaoti_img"
          :src="imgUrlPrefix1 + 'biaoti_1.png'"
          alt=""
        />
        <div class="lf_enter_box">
          <img
            class="lf_enter_img"
            :src="imgUrlPrefix1 + 'enter_1.png'"
            alt=""
            @click="getSection1"
          />
          <!-- 点击进入 -->
        </div>
      </div>

      <div class="lf_bottom_box lf_bottom_box_1 h5_box">
        <div class="lf_code_gslogo_box" style="position: fixed; bottom: 20px">
          <!-- <img class="lf_code_img" :src="imgUrlPrefix1 + 'code_1.png'" alt="" /> -->

          <img
            class="lf_gslogo_img"
            :src="imgUrlPrefix1 + 'gslogo_1.png'"
            alt=""
          />
        </div>
        <div class="lf_bottom_middle_box" style="position: fixed; bottom: 10px">
          <CopyrightOnlyText />
        </div>
      </div>
    </div>

    <!-- //竖版模式 end-->
  </div>
</template>

<script>
import { requestHttp } from "@/network/request";
import { errorMessage } from "@/utils/message";
import {
  getLocalStorageCacheItem,
  userQuit,
  getImgUrlPrefix,
  getSections1Colors,
  setPlayCount,
  getCommonConfigValue,
} from "@/utils/commonFunc";
import Fotter from "../components/Fotter.vue";
import Welcome from "@/components/Welcome.vue";
import CopyrightOnlyText from "@/components/CopyrightOnlyText.vue";

export default {
  name: "Home",
  components: { Welcome, CopyrightOnlyText },
  props: {
    msg: String,
  },
  data() {
    return {
      configValue: getCommonConfigValue(),
      imgUrlPrefix1: getImgUrlPrefix(),
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      loading: false,
      section1Data: [],
      haveLogin: false,
    };
  },
  methods: {
    //检查横屏
    checkScreenDirection() {
      const that = this;

      window.addEventListener(
        "onorientationchange" in window ? "orientationchange" : "resize",
        function () {
          if (true) {
            if (window.orientation === 180 || window.orientation === 0) {
              window.location.reload();
            }
            if (window.orientation === 90 || window.orientation === -90) {
              that.initMounted();
            }
          }
        },
        false
      );
    },
    //取一级版块
    getSection1() {
      let that = this;
      const tmpId = that.$route.query.sections1Id;
      setPlayCount(tmpId, "sections1");

      that.$router.push({
        path: "/second",
        query: { sections1Id: tmpId },
      });
    },
    setCount(sections1id) {
      let tmpUrl = "setCount";
      let tmpParams = {
        type: "sections1",
        id: sections1id,
      };

      requestHttp(
        {
          headerParams: tmpParams,
          url: tmpUrl,
        },
        function () {
          //
        },
        function (errData) {
          //处理错误信息
          errorMessage(errData.msg);
        }
      );
    },
    quit() {
      userQuit();
      this.$router.replace("/Login");
    },
    setColors() {
      const colors = getSections1Colors();
      this.$refs.refMain.style.setProperty(
        "--Color_footer",
        colors.color_footer
      );

      this.$refs.refMain.style.setProperty(
        "--Color_section2_font",
        colors.color_section2_font
      );
    },
  },
  mounted() {
    var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
        if (window.fullHeight < window.fullWidth) {
          document.documentElement.style.fontSize =
            window.fullWidth / 8.001 + "px";
        } else {
          document.documentElement.style.fontSize =
            window.fullWidth / 3.125 + "px";
        }
      })();
    };

    setTimeout(() => {
      if (getLocalStorageCacheItem("sshname")) {
        that.haveLogin = true;
      }
    }, 1500);

    that.$refs.refMain.style.setProperty(
      "--Img_h5_bottom_backimg",
      `url(${this.imgUrlPrefix1}/首页底部图形.png)`
    );

    this.setColors();

    this.checkScreenDirection();
  },
};
</script>

<style scoped>
#backimg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}

.lf_top_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lf_logo {
  width: 1.932rem;
  height: 0.254rem;
  margin-left: 0.3rem;
  margin-top: 0.14rem;
}

.lf_login_box {
  display: flex;
  align-items: center;
  font-size: 0.12rem;
  color: #fff;
}

.lf_login_exit {
  /* width: 0.281rem; */
  height: 0.14rem;
  margin: 0 0.2rem;
}

.lf_middle_box {
  display: flex;
  flex-direction: column;
  margin-left: 0.6rem;
  margin-top: 1.373rem;
}

.lf_biaoti_img {
  width: 2.828rem;
  height: 0.701rem;
}

.lf_enter_img {
  width: 1.303rem;
  /* height: 0.401rem; */
  margin-top: 0.18rem;
  z-index: 999;
}

.lf_bottom_box {
  height: 0.57rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.056rem;
  color: var(--Color_footer);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.lf_bottom_middle_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.lf_bottom_middle_box img {
  width: 1.119rem;
  height: 0.151rem;
  margin-bottom: 0.168rem;
}

.lf_code_img {
  width: 0.399rem;
  height: 0.512rem;
  margin-right: 0.143rem;
  margin-bottom: 0.08rem;
}

/* 竖版 */
.lf_top_box_1 {
  justify-content: center;
  margin-top: 0.12rem;
}

.lf_top_box_1 .lf_login_box {
  /* color: var(--Color_section2_font); */
  color: #fff;
  font-size: 0.16rem !important;
  justify-content: space-between;
  width: 100%;
}

.lf_top_box_1 .lf_login_exit {
  /* width: 0.375rem; */
  height: 0.42rem;
  margin-left: 0.3rem;
  margin-right: 0.2rem;
}

.lf_middle_box_1 {
  margin: 2.45rem auto 0 !important;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .lf_middle_box_1 {
    margin: 1.15rem auto 0 !important;
    align-items: center;
  }
}

.lf_logo_1 {
  width: 0.86rem;
  height: 0.414rem;
}

.lf_middle_box_1 .lf_biaoti_img {
  width: 2.446rem;
  height: 0.622rem;
}

.lf_middle_box_1 .lf_enter_img {
  /* width: 0.846rem; */
  width: 1.8rem;
  margin-top: 0.5rem;
}

.lf_enter_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--Color_section2_font);
  font-size: 0.112rem;
}

.lf_bottom_box_1 {
  height: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.062rem;
  color: var(--Color_footer);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.lf_bottom_box_1 .lf_bottom_middle_box {
  font-size: 0.062rem;
  color: var(--Color_footer);
}

.lf_code_gslogo_box {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: flex-end;
}

.lf_code_gslogo_box .lf_code_img {
  width: 0.395rem;
  height: 0.488rem;
  margin-left: 0.13rem;
}

.lf_code_gslogo_box .lf_gslogo_img {
  width: 1.186rem;
  height: 0.161rem;
  margin-right: 0.15rem;
}

.lf_enter_img:hover,
.lf_login_exit:hover {
  transform: scale(1.05);
  cursor: pointer;
}
.h5_box {
  height: 1.5rem;
  background: var(--Img_h5_bottom_backimg) no-repeat;
  /* background-size: 6rem; */
  background-position: center;
  background-size: cover;
}
</style>
