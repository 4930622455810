<template>
  <div style="height: 99%; max-width: 99%">
    <video
      x5-video-player-type="h5-page"
      x5-video-orientation="landscape|portrait"
      webkit-playsinline="true"
      playsinline="true"
      id="videoPlayer"
      controlsList="nodownload"
      :disablePictureInPicture="true"
      style="height: 100%; max-width: 100%"
      ref="refVideoPlayer"
      class="video-js vjs-big-play-centered"
      :style="{ visibility: videoUrl ? 'visible' : 'hidden' }"
    ></video>
  </div>
</template>

<script>
import {
  toRefs,
  onMounted,
  defineComponent,
  reactive,
  ref,
  onUnmounted,
  watch,
} from "vue";
import videojs, { log } from "video.js";
import "video.js/dist/video-js.css";
import $ from "jquery";

export default defineComponent({
  name: "VideoPlayer",
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    videoUrl: {
      type: String,
      default: "",
    },
  },
  emits: ["emitFullScreenChange"],
  setup(props, { emit }) {
    const refVideoPlayer = ref();

    const state = reactive({
      player: null,
      videoOptions: {
        autoplayer: false,
        controls: true,
        sources: [],
      },
    });

    const initPlayer = () => {
      setTimeout(() => {
        if (state.player) {
          state.player;
        }
        state.videoOptions.sources = [];
        state.videoOptions.sources.push({
          src: props.videoUrl,
          type: "video/mp4",
        });

        state.player = videojs(refVideoPlayer.value, state.videoOptions, () => {
          console.log("---a,video");
        });

        state.player.on("fullscreenchange", (e) => {
          const t = document.fullscreenElement ? true : false;
          // console.log("fullscreenchange========bbb---", t);
          emit("emitFullScreenChange", {
            success: t,
          });
        });

        $("#videoPlayer").bind("contextmenu", function () {
          return false;
        });
      }, 2000);
    };

    const changeUrl = () => {
      state.player = videojs(refVideoPlayer.value, state.videoOptions, () => {
        // console.log("---a,video");
      });
      state.player.src({
        src: props.videoUrl,
      });
    };

    onMounted(() => {
      initPlayer();
    });

    watch(
      () => props.videoUrl,
      () => {
        changeUrl();
      }
    );

    watch(
      () => props.isMobile,
      () => {
        changeUrl();
      }
    );
    return {
      ...toRefs(state),
      refVideoPlayer,
      changeUrl,
    };
  },
});
</script>

<style>
video::-internal-media-controls-download-button {
  display: none;
}
video::-webkit-media-controls-enclosure {
  overflow: hidden;
}
video::-webkit-media-controls-panel {
  width: calc(100% + 50px);
}
</style>