<template>
  <div id="iframeScaleDiv" v-if="showFullScreenPc.show">
    <iframe
      id="ifr_diagnose_pc_fullscreen"
      scrolling="no"
      frameborder="0"
      style="width: 100%; height: 100vh"
      :src="
        hostName + lessionsData.playList[lessionsData.playingIndex]?.file_url
      "
    />
  </div>
  <!-- <button style="" v-if="showFullScreenPc.show" @click="exitFullScreenPc">
    退出全屏
  </button> -->

  <i
    id="closeBtn"
    v-show="showFullScreenPc.show"
    @click="exitFullScreenPc"
    class="iconfont icon-guanbi"
    style="
      font-size: 0.33rem;
      z-index: 9999910000000;
      position: absolute;
      bottom: 50px;
      right: 20px;
      cursor: pointer;
      color: red;
    "
  ></i>

  <div class="lf_content_box" ref="refContent" v-if="!showFullScreenPc.show">
    <Header :isMobile="isMobile"></Header>
    <el-row>
      <el-col :xs="24" :sm="24" :xl="24" class="" v-if="!isMobile">
        <div class="lf_main_box_H5">
          <el-scrollbar style="width: 87%; margin-left: 0.48rem">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                margin-top: 15px;
                margin-bottom: 10px;
              "
            >
              <Sections2 :isMobile="isMobile" />
            </div>
          </el-scrollbar>

          <div class="lf_main_box_mid">
            <div
              id="pc_video"
              class="lf_main_box_mid_video"
              style="display: flex; justify-content: center"
            >
              <!-- :class="{
                  lf_main_box_mid_video_fullscreen: showFullScreenPc.show,
                }" -->
              <iframe
                v-if="
                  lessionsData.playList.length > 0 && !showFullScreenPc.show
                "
                id="ifr_diagnose_pc"
                scrolling="no"
                frameborder="0"
                style="width: 4rem"
                :src="
                  hostName +
                  lessionsData.playList[lessionsData.playingIndex]?.file_url
                "
              >
              </iframe>
            </div>
            <!-- <FullScreen /> -->

            <!-- <button @click="toggleFullscreen">全屏</button> -->
            <div class="fullscreen" id="fullscreen"></div>
            <div class="lf_main_box_mid_video_btn_box">
              <div class="lf_main_box_mid_video_btn_box_top">
                <div
                  class="lf_main_box_mid_video_btn_box_top_1"
                  @click="changeScroll('left')"
                >
                  <img
                    class="ysj_pointer"
                    :src="imgUrlPrefix1 + 'arrow_left.png'"
                    alt=""
                  />
                </div>
                <el-scrollbar
                  style="height: 35px !important ; line-height: 35px"
                  ref="refSwiperPagination"
                >
                  <div class="lf_main_box_mid_video_btn_box_top_2">
                    <template
                      v-for="(item, index) in pageSet.pageArr"
                      :key="index"
                    >
                      <div
                        class="ysj_pointer2"
                        @click="changePage(item.num)"
                        :class="{ lf_select: pageSet.curr == item.num }"
                      >
                        {{ item.text }}
                      </div>
                    </template>
                  </div>
                </el-scrollbar>
                <div
                  class="lf_main_box_mid_video_btn_box_top_1"
                  @click="changeScroll('right')"
                >
                  <img
                    class="ysj_pointer"
                    :src="imgUrlPrefix1 + 'arrow_right.png'"
                    alt=""
                  />
                </div>
              </div>
              <div
                class="lf_main_box_mid_video_btn_box_top_middle"
                v-if="lessionsData.playList.length > 0"
              >
                <template
                  v-for="(item, index) in lessionsData.playList"
                  :key="index"
                >
                  <div
                    class="ysj_pointer"
                    v-if="
                      index < pageSet.curr * pageSet.size &&
                      index >= (pageSet.curr - 1) * pageSet.size
                    "
                    @click="changeLession(index, item)"
                    :class="{ lf_select: lessionsData.playingIndex == index }"
                  >
                    {{ item.title }}
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div
            class=""
            style="
              width: 6rem;
              margin: 0 auto;
              display: flex;
              justify-content: center;
            "
          >
            <div
              class=""
              style="display: flex; justify-content: flex-end; width: 4rem"
            >
              <img
                @click="toggleFullscreenNew()"
                src="@/assets/images/fullscreen.png"
                style="width: 100px; cursor: pointer; height: 32px"
                alt=""
              />
            </div>
            <div class="" style="width: 1.7rem; margin-left: 7px"></div>
          </div>

          <div class="lf_main_box_bottom">
            <div class="lf_main_box_bottom_1">
              {{ lessionsData.playList[lessionsData.playingIndex]?.title }}

              <!-- <i
                class="iconfont icon-quanping2"
                @click="toggleFullscreenNew()"
                title="全屏"
                style="
                  cursor: pointer;
                  color: var(--Color_section2_font);
                  margin-left: 20px;
                "
              ></i> -->
            </div>

            <div class="lf_main_box_bottom_2">
              <div class="lf_main_box_bottom_2_1">
                {{
                  lessionsData.playList[lessionsData.playingIndex]?.detail_words
                }}
              </div>
              <img
                class="lf_main_box_bottom_2_2"
                :src="imgUrlPrefix1 + 'code.png'"
              />
            </div>
          </div>
        </div>
      </el-col>

      <!-- 手机版 -->
      <el-col
        v-if="isMobile"
        :xs="24"
        :sm="24"
        :xl="24"
        class=""
        style="padding-bottom: 50px"
      >
        <div class="lf_main_box_H5_phone">
          <div
            class="lf_main_box_mid_phone"
            style="display: flex; justify-content: center"
          >
            <iframe
              v-if="lessionsData.playList.length > 0"
              id="ifr_diagnose_pc_phone"
              scrolling="no"
              frameborder="0"
              style="height: 99%; max-width: 100%"
              :src="
                hostName +
                lessionsData.playList[lessionsData.playingIndex]?.file_url
              "
            >
              your browser does not support the video tag
            </iframe>
          </div>
          <div style="width: 100%; display: flex; justify-content: flex-end">
            <img
              @click="toggleFullscreenNew()"
              src="@/assets/images/fullscreen.png"
              style="width: 100px; cursor: pointer"
              alt=""
            />
          </div>

          <div class="lf_main_box_bottom_phone">
            <div class="lf_main_box_bottom_1_phone">
              {{ lessionsData.playList[lessionsData.playingIndex]?.title }}

              <i
                v-if="false"
                class="iconfont icon-quanping2"
                @click="toggleFullscreenNew()"
                title="全屏"
                style="
                  cursor: pointer;
                  color: var(--Color_section2_font);
                  margin-left: 20px;
                "
              ></i>
            </div>
            <div class="lf_main_box_bottom_3_phone">
              <view
                class="lf_main_box_bottom_3_1_phone"
                style="font-size: 0.16rem; font-weight: bold"
                >简介</view
              >
              <view @click="showtext" class="lf_main_box_bottom_3_2_phone"
                >{{ showtextFlag ? "收起" : "展开"
                }}<img
                  :style="
                    showtextFlag
                      ? 'transform: rotate(90deg)'
                      : 'transform: rotate(-90deg)'
                  "
                  :src="imgUrlPrefix1 + 'back.png'"
                  alt=""
              /></view>
            </div>
            <div class="lf_main_box_bottom_2_phone">
              <div
                class=""
                :class="
                  showtextFlag
                    ? 'lf_main_box_bottom_2_1_phone_open'
                    : 'lf_main_box_bottom_2_1_phone'
                "
              >
                {{
                  lessionsData.playList[lessionsData.playingIndex]?.detail_words
                }}
              </div>
            </div>
          </div>
          <div class="lf_btn_select_video_s">
            <div class="lf_btn_select_video_s_tit">
              <div
                class="lf_btn_select_video_s_tit_1"
                @click="mobile.showAlertAll = true"
              >
                选集
              </div>
              <div
                class="lf_btn_select_video_s_tit_2"
                @click="mobile.showAlert = true"
              >
                显示全部 <img :src="imgUrlPrefix1 + 'back.png'" alt="" />
              </div>
            </div>
            <div class="lf_btn_select_video_s_cons">
              <div class="lf_scroll_box">
                <!-- <div class="lf_btn_select_video_cons_select">渤海明珠·天津</div>
								<div>渤海明珠·天津</div><div>渤海明珠·天津</div><div>渤海明珠·天津</div><div>渤海明珠·天津</div><div>渤海明珠·天津</div><div>渤海明珠·天津</div> -->

                <template
                  v-for="(item, index) in lessionsData.playList"
                  :key="index"
                >
                  <div
                    v-if="index < 10"
                    :style="item.title.length > 8 ? 'font-size:12px;' : ''"
                    @click="changeLession(index, item)"
                    :class="{
                      lf_btn_select_video_cons_select:
                        lessionsData.playingIndex == index,
                    }"
                  >
                    {{ item.title }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div
          class="lf_alert_box"
          v-show="mobile.showAlert"
          @click="mobile.showAlert = false"
        >
          <div class="lf_main_box_mid_video_btn_box_phone" @click.stop="false">
            <div class="lf_btn_select_video_s_tit">
              <div class="lf_btn_select_video_s_tit_1">选集</div>
              <div
                class="lf_btn_select_video_s_tit_2"
                @click="mobile.showAlert = false"
              >
                <img :src="imgUrlPrefix1 + 'close.png'" alt="" />
              </div>
            </div>
            <div class="lf_main_box_mid_video_btn_box_top">
              <div class="lf_main_box_mid_video_btn_box_top_1">
                <img :src="imgUrlPrefix1 + 'arrow_left.png'" alt="" />
              </div>
              <el-scrollbar
                style="height: 35px !important ; line-height: 35px"
                ref="refSwiperPagination"
              >
                <div class="lf_main_box_mid_video_btn_box_top_2">
                  <template
                    v-for="(item, index) in pageSet.pageArr"
                    :key="index"
                  >
                    <div
                      @click="changePage(item.num)"
                      :class="{ lf_select: pageSet.curr == item.num }"
                    >
                      {{ item.text }}
                    </div>
                  </template>
                </div>
              </el-scrollbar>
              <div class="lf_main_box_mid_video_btn_box_top_1">
                <img :src="imgUrlPrefix1 + 'arrow_right.png'" alt="" />
              </div>
            </div>
            <div
              class="lf_main_box_mid_video_btn_box_top_middle"
              style="padding-bottom: 67px"
            >
              <template
                v-for="(item, index) in lessionsData.playList"
                :key="index"
              >
                <div
                  v-if="
                    index <= pageSet.curr * pageSet.size &&
                    index >= (pageSet.curr - 1) * pageSet.size
                  "
                  @click="changeLession(index, item)"
                  :class="{ lf_select: lessionsData.playingIndex == index }"
                >
                  {{ item.title }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- backgroundS="#dfefdf" -->
    <Fotter :loadedData="loadedData" :isMobile="isMobile"></Fotter>
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Fotter from "../components/Fotter.vue";
import Sections2 from "./components/Sections2.vue";
import { requestHttp } from "@/network/request";
import { errorMessage, successMessage } from "@/utils/message";
import {
  getLessionsWhere,
  makeUrl,
  setPlayCount,
  checkWx,
} from "@/utils/commonFunc";
import {
  getImgUrlPrefix,
  getSections1Colors,
  checkMobile,
} from "@/utils/commonFunc";

import $ from "jquery";
export default {
  name: "Home",
  components: {
    Header,
    Fotter,
    Sections2,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      hostName: window.location.protocol + "//" + window.location.host,
      isMobile: false,

      loadedData: false, //加载成功
      imgUrlPrefix1: getImgUrlPrefix(),
      loading: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      showtextFlag: false,
      routerData: {
        searchType: "", //搜索类型：null、wordIndex、searchText
        searchValue: "", //搜索关键字
        lessionId: "", //课程id
        sections2Id: "", //版块2id
        sections1Id: "", //版块1id
      },
      lessionsData: {
        playingIndex: 0, //正在播放中的
        playList: [], //播放列表
        sections2List: [], //二级版块数据
      },
      pageSet: {
        curr: 1,
        size: 10,
        page_count: 0,
        pageArr: [
          {
            num: 1,
            text: "1-30",
          },
        ],
      },
      mobile: {
        showAlert: false, //显示面板
        showAlertAll: true,
      },
      showFullScreenPc: {
        show: false,
        scale: 1,
      },
    };
  },
  mounted() {
    this.initMounted();
    this.checkScreenDirection();
  },
  methods: {
    initMounted() {
      var that = this;
      // <!--把window.onresize事件挂在到mounted函数上-->
      window.onresize = () => {
        return (() => {
          window.fullHeight = document.documentElement.clientHeight;
          window.fullWidth = document.documentElement.clientWidth;
          that.windowHeight = window.fullHeight; // 高
          that.windowWidth = window.fullWidth; // 宽
          if (window.fullHeight < window.fullWidth) {
            document.documentElement.style.fontSize =
              window.fullWidth / 8.001 + "px";
          } else {
            document.documentElement.style.fontSize =
              window.fullWidth / 3.125 + "px";
          }

          this.isMobile = checkMobile();
          // window.history.go(0);
        })();
      };

      let tmpParams = this.$route.query;
      this.routerData.searchType = tmpParams?.searchType;
      this.routerData.searchValue = tmpParams?.searchValue;
      this.routerData.lessionId = tmpParams?.lessionId;
      this.routerData.sections2Id = tmpParams?.sections2Id;
      this.routerData.sections1Id = tmpParams?.sections1Id;

      this.getSections2();

      this.initData();

      setTimeout(() => {
        if (!this.isMobile) {
          this.$refs.refContent.style.setProperty(
            "--Img_backimg_2",
            `url(${this.imgUrlPrefix1}/backimg_2.jpg)`
          );
        }
      }, 1000);

      this.setColors();

      this.isMobile = checkMobile();

      this.movedrag();
    },
    movedrag() {
      const o = document.getElementById("closeBtn"); //鼠标按下
      o.onmousedown = function (e) {
        //鼠标相对于盒子的位置
        const offsetX = e.clientX - o.offsetLeft;
        const offsetY = e.clientY - o.offsetTop; //鼠标移动
        document.onmousemove = function (e) {
          o.style.left = e.clientX - offsetX + "px";
          o.style.top = e.clientY - offsetY + "px";
        }; //鼠标抬起
        document.onmouseup = function () {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      };
    },

    //检查横屏
    checkScreenDirection() {
      const that = this;

      window.addEventListener(
        "onorientationchange" in window ? "orientationchange" : "resize",
        function () {
          if (!checkWx()) {
            if (window.orientation === 180 || window.orientation === 0) {
              // that.isMobile = checkMobile();
              that.initMounted();
            }
            if (window.orientation === 90 || window.orientation === -90) {
              // that.isMobile = checkMobile();
              that.initMounted();
            }
          }
        },
        false
      );
    },

    async initData() {
      let that = this;
      const returnData = await getLessionsWhere(this.$route);
      // debugger;

      that.lessionsData.playList = returnData.data.list;
      that.pageSet.page_count = returnData.data.page_count;
      that.pageSet.size = returnData.data.size;
      that.pageSet.pageArr = that.makePageArr();

      let tmpIndex = 0;
      if (this.$route.query?.lessionId) {
        const tmpId = this.$route.query?.lessionId;
        this.lessionsData.playList.forEach((item, index) => {
          if (item.id == tmpId) {
            tmpIndex = index;
          }
        });

        setPlayCount(tmpId, "lessions");
      }

      that.lessionsData.playingIndex = tmpIndex;

      that.loadedData = true;
    },
    showtext() {
      if (this.showtextFlag == true) {
        this.showtextFlag = false;
      } else {
        this.showtextFlag = true;
      }
    },

    makePageArr() {
      let that = this;
      let tmpArr = [];
      for (let i = 1; i <= that.pageSet.page_count; i++) {
        tmpArr.push({
          num: i,
          text: that.pageSet.size * (i - 1) + 1 + "-" + i * that.pageSet.size,
        });
      }
      return tmpArr;
    },
    changeLession(index, item) {
      let that = this;

      //判断是否为外部链接
      if (item.type == "link") {
        window.open(item.link_url);
        return;
      }

      //if (item.type != "h5") {
      //
      // setPlayCount(item.id, "lessions");
      const tmpParams = makeUrl(item);
      that.$router.push({
        path: tmpParams.path,
        query: tmpParams.query,
      });
      /* } else {
        this.lessionsData.playingIndex = index ? index : 0;
        this.mobile.showAlert = false;

        setPlayCount(item.id, "lessions");
      }*/
    },
    changePage(pageNum) {
      this.pageSet.curr = pageNum;
    },
    getSections2() {
      let that = this;
      that.loading = true;
      //发送验证码
      requestHttp(
        {
          headerParams: {
            parent_id: that.routerData.sections1Id,
          },
          url: "/getSection2",
          // url: "/getLessions",
        },
        function (dd) {
          let tmpData = dd.data.data;
          console.log("dd---", dd);

          that.lessionsData.sections2List = tmpData.list;

          that.loading = false;
        },
        function (errData) {
          //处理错误信息
          that.loading = false;
          errorMessage(errData.msg);
        }
      );
    },
    toggleFullscreen() {
      // window.open(
      //   this.lessionsData.playList[this.lessionsData.playingIndex]?.file_url
      // );
    },
    toggleFullscreenNew() {
      this.showFullScreenPc.show = true;
    },
    exitFullScreenPc() {
      this.showFullScreenPc.show = false;
      document.location.reload();
    },
    pxToRem(rem = 0.46) {
      // var html = document.documentElement;
      var html = document.getElementById("ifr_diagnose_pc");
      var hWidth = html.getBoundingClientRect().width;
      hWidth = Math.min(hWidth, 750);
      return (rem * hWidth) / 3.75;
    },
    toggleFullscreenold() {
      if (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        document.getElementById("ifr_diagnose_pc").requestFullscreen();

        if (document.documentElement.requestFullscreen) {
          document.getElementById("ifr_diagnose_pc").requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.getElementById("ifr_diagnose_pc").mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document
            .getElementById("ifr_diagnose_pc")
            .webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (document.body.msRequestFullscreen) {
          document.getElementById("ifr_diagnose_pc").mozRequestFullScreen();
        }
      }

      //更新iframe定位
      update_iframe_pos();
    },
    toggleFullscreenPhone() {
      if (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        document.getElementById("ifr_diagnose_pc_phone").requestFullscreen();

        if (document.documentElement.requestFullscreen) {
          document.getElementById("ifr_diagnose_pc_phone").requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document
            .getElementById("ifr_diagnose_pc_phone")
            .mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document
            .getElementById("ifr_diagnose_pc_phone")
            .webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (document.body.msRequestFullscreen) {
          document
            .getElementById("ifr_diagnose_pc_phone")
            .mozRequestFullScreen();
        }
      }

      //更新iframe定位
      update_iframe_pos_phone();
    },
    setColors() {
      const colors = getSections1Colors();

      this.$refs.refContent.style.setProperty(
        "--Color_section2_font",
        colors.color_section2_font
      );

      this.$refs.refContent.style.setProperty(
        "--Color_play_resume_font",
        colors.color_play_resume_font
      );
    },
    changeScroll(direction = "") {
      // debugger;

      if (direction == "left") {
        //
        this.$refs.refSwiperPagination.setScrollLeft(0);
      }
      if (direction == "right") {
        //
        this.$refs.refSwiperPagination.setScrollLeft(1000);
      }
    },
  },
  watch: {
    $route: function () {
      this.initData();
    },
  },
};

//退出全屏时恢复全屏按钮、iframe的定位方式
const update_iframe_pos = function () {
  if (
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement
  ) {
    document.getElementById("ifr_diagnose_pc").addClass("ifr_fixed");
    document.getElementById("fullscreen").addClass("full_fixed");
  } else {
    document.getElementById("ifr_diagnose_pc").removeClass("ifr_fixed");
    document.getElementById("fullscreen").removeClass("full_fixed");
  }
};

//退出全屏时恢复全屏按钮、iframe的定位方式
const update_iframe_pos_phone = function () {
  if (
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement
  ) {
    document.getElementById("ifr_diagnose_pc_phone").addClass("ifr_fixed");
    document.getElementById("fullscreen").addClass("full_fixed");
  } else {
    document.getElementById("ifr_diagnose_pc_phone").removeClass("ifr_fixed");
    document.getElementById("fullscreen").removeClass("full_fixed");
  }
};

//添加退出全屏时的监听事件
window.addEventListener("fullscreenchange", function () {
  update_iframe_pos();
});
window.addEventListener("mozfullscreenchange", function () {
  update_iframe_pos();
});
window.addEventListener("webkitfullscreenchange", function () {
  update_iframe_pos();
});
window.addEventListener("msfullscreenchange", function () {
  update_iframe_pos();
});
</script>

<style scoped>
.ifr_fixed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.lf_content_box {
  background: var(--Img_backimg_2) no-repeat;
  /* background-size: 8.001rem 6.432rem; */
  background-size: 8.001rem 7.432rem;
}

.lf_main_box_H5 {
  width: 80%;
  margin: 0 auto 0.684rem;
}

.lf_main_box_top {
  display: flex;
  align-items: center;
  margin: 0.1rem 0.3rem;
}

.lf_main_box_top div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Color_play_resume_font);
  font-size: 0.08rem;
  width: 0.7rem;
  word-break: keep-all;
  margin: 0 0.12rem;
}

.lf_main_box_top .lf_select {
  border-bottom: 2px solid var(--Color_section2_font);
  color: var(--Color_section2_font);
}

.lf_main_box_mid {
  width: 6rem;
  /* height: 2.907rem; */
  height: 2rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lf_main_box_mid_video {
  width: 4rem;
  /* height: 2.907rem; */
  height: 2rem;

  /* background-color: #000000; */
}
.lf_main_box_mid_video_btn_box {
  /* width: 2.126rem; */
  /* height: 2.9rem; */
  margin-left: 5px;
  width: 1.7rem;
  height: 2rem;
  background-color: #fff;
  border-radius: 0.16rem;
  border: 1px solid var(--Color_section2_font);
}

.lf_main_box_mid_video_btn_box_phone {
  /* width: 2.126rem; */
  /* height: 2.9rem; */
  /* margin-left: 5px; */
  /* max-width: 1.7rem; */
  height: 2rem;
  background-color: #fff;
  border-radius: 0.16rem;
  border: 1px solid var(--Color_section2_font);
}
.lf_main_box_mid_video_btn_box_top {
  height: 0.36rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lf_main_box_mid_video_btn_box_top_1 {
  width: 0.16rem;
  height: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lf_main_box_mid_video_btn_box_top_1 img {
  width: 0.08rem;
  height: 0.08rem;
}
.lf_main_box_mid_video_btn_box_top_2 {
  width: 1.72rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
}
.lf_main_box_mid_video_btn_box_top_2::-webkit-scrollbar {
  display: none;
}
.lf_main_box_mid_video_btn_box_top_2 div {
  display: flex;
  align-items: center;
  font-size: 0.08rem;
  margin-right: 0.1rem;
  flex-shrink: 0;
  /* border-bottom: 1px solid var(--Color_play_resume_font); */
  text-decoration: underline;
}
.lf_main_box_mid_video_btn_box_top_2 .lf_select {
  /* border-bottom: 2px solid var(--Color_section2_font); */
  text-decoration: underline;

  color: var(--Color_section2_font);
  font-weight: bold;
}
.lf_main_box_mid_video_btn_box_top_2 div:nth-last-of-type(1) {
  margin-right: 0rem;
}
.lf_main_box_mid_video_btn_box_top_middle {
  width: 90%;
  height: 1.6rem;
  max-height: 1.6rem;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.125rem;
  justify-content: center;
}
.lf_main_box_mid_video_btn_box_top_middle div {
  /* width: 0.82rem; */
  width: 40%;
  /* width: 130px; */
  height: 0.235rem;
  border: 1px solid var(--Color_section2_font);
  border-radius: 0.09rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: var(--Color_play_resume_font);
  font-size: 0.066rem;
  /* font-size: 13px; */
  margin: 0 0.05rem 0.05rem 0;
  font-weight: 400;
  padding: 0.001rem;
  text-align: center;
}
.lf_main_box_mid_video_btn_box_top_middle .lf_select {
  background-color: var(--Color_section2_font);
  color: #fff;
}
.lf_main_box_mid_video_btn_box_top_middle::-webkit-scrollbar {
  width: 0.06rem;
  border-radius: 0.03rem;
}
.lf_main_box_mid_video_btn_box_top_middle::-webkit-scrollbar-track {
  width: 0.06rem;
  background: #fff;
  border-radius: 0.03rem;
}
.lf_main_box_mid_video_btn_box_top_middle::-webkit-scrollbar-thumb {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: var(--Color_section2_font);
}
.lf_main_box_mid_video_btn_box_top_middle::-webkit-scrollbar-thumb:hover {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: var(--Color_section2_font);
}
.lf_main_box_mid_video_btn_box_top_middle::-webkit-scrollbar-thumb:active {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: var(--Color_section2_font);
}
.lf_main_box_bottom {
  width: 6rem;
  /* margin: 0.1rem auto 0.684rem; */
  margin-left: 0.52rem;
}

.lf_main_box_bottom_1 {
  color: var(--Color_play_resume_font);
  font-size: 30px;
  font-weight: bold;
  margin: 0.15rem 0;
  display: flex;
  align-items: flex-end;
}
.lf_main_box_bottom_1 img {
  width: 25px;
  /* height: 0.098rem; */
  margin-left: 0.13rem;
}
.lf_main_box_bottom_2 {
  display: flex;
  width: 6.544rem;
  margin: 0 auto;
  justify-content: space-between;
}

.lf_main_box_bottom_2_1 {
  font-size: 13px;
  margin-right: 0.25rem;
  width: 4rem;
  line-height: 32px;
}

.lf_main_box_bottom_2_2 {
  width: 0.399rem !important;
  height: 0.512rem !important;
}

.lf_main_box_mid_phone {
  width: 100%;
  height: 1.77rem;
  background: #000000;
}

.lf_main_box_bottom_1_phone {
  font-size: 0.28rem;
  font-weight: bold;
  margin: 0.1rem 0.14rem;
  margin-bottom: 0.16rem;
  display: flex;
  align-items: flex-end;
}
.lf_main_box_bottom_1_phone img {
  /* width: 0.134rem; */
  width: 25px;
  /* height: 0.098rem; */
  margin-left: 0.1rem;
}

.lf_main_box_bottom_3_phone {
  display: flex;
  margin: 0 0.14rem;
  justify-content: space-between;
}

.lf_main_box_bottom_3_1_phone {
  font-size: 0.173rem;
  color: var(--Color_play_resume_font);
}

.lf_main_box_bottom_3_2_phone {
  font-size: 0.15rem;
  color: var(--Color_section2_font);
  display: flex;
  align-items: center;
  justify-content: center;
}

.lf_main_box_bottom_3_2_phone img {
  width: 0.04rem;
  height: 0.12rem;
  margin: 0 0.06rem;
}

.lf_main_box_bottom_2_1_phone {
  margin: 0.14rem 0.14rem;
  font-size: 0.15rem;
  color: var(--Color_play_resume_font);

  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 0.4rem;
}
.lf_main_box_bottom_2_1_phone_open {
  overflow: unset;

  margin: 0.14rem 0.14rem;
  font-size: 0.15rem;
  color: var(--Color_play_resume_font);

  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;

  line-height: 32px;
}
.lf_btn_select_video_s_tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lf_btn_select_video_s_tit_1 {
  font-size: 0.16rem;
  font-weight: bold;
  color: var(--Color_play_resume_font);
  margin-left: 0.16rem;
}
.lf_btn_select_video_s_tit_2 {
  font-size: 0.14rem;
  font-weight: 400;
  color: var(--Color_section2_font);
  display: flex;
  align-items: center;
  margin-right: 0.18rem;
}
.lf_btn_select_video_s_tit_2 img {
  transform: rotate(180deg);
  margin-left: 0.06rem;
  width: 0.04rem;
  height: 0.11rem;
}
.lf_btn_select_video_s_cons {
  width: 100%;
  overflow-x: scroll;
}
.lf_btn_select_video_s_cons .lf_scroll_box {
  display: flex;
  align-items: center;
}
.lf_btn_select_video_s_cons .lf_scroll_box div {
  width: 0.88rem;
  height: 0.58rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--Color_section2_font);
  border-radius: 0.14rem;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0.17rem 0.08rem 0.17rem 0;
  box-sizing: border-box;
  font-size: 0.15rem;
  padding: 0.09rem;
  text-align: center;
}
.lf_btn_select_video_s_cons .lf_scroll_box .lf_btn_select_video_cons_select {
  background-color: var(--Color_section2_font);
  color: #fff;
}
.lf_alert_box {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.29);
  z-index: 199999;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_phone {
  width: 100%;
  height: 87%;
  background-color: #fff;
  border-radius: unset;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top {
  height: 0.36rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_1 {
  width: 0.28rem;
  height: 0.28rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_1 img {
  width: 0.2rem;
  height: 0.2rem;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_2 {
  width: 2.92rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_2::-webkit-scrollbar {
  display: none;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_2 div {
  display: flex;
  align-items: center;
  font-size: 0.12rem;
  margin-right: 0.1rem;
  flex-shrink: 0;
  /* border-bottom: 1px solid var(--Color_play_resume_font); */
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_2 .lf_select {
  /* border-bottom: 2px solid var(--Color_section2_font); */
  font-weight: bold;
  color: var(--Color_section2_font);
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_2 div:nth-last-of-type(1) {
  margin-right: 0rem;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_middle {
  width: 2.91rem;
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.125rem;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_middle div {
  width: 1.25rem;
  height: 0.435rem;
  border: 1px solid var(--Color_section2_font);
  border-radius: 0.18rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: var(--Color_play_resume_font);
  font-size: 0.14rem;
  margin: 0 0.05rem 0.05rem 0;
  font-weight: 400;
}
.lf_alert_box .lf_main_box_mid_video_btn_box_top_middle .lf_select {
  background-color: var(--Color_section2_font);
  color: #fff;
}
.lf_alert_box .lf_btn_select_video_s_tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.lf_alert_box .lf_btn_select_video_s_tit_1 {
  font-size: 0.17rem;
  font-weight: bold;
  color: var(--Color_play_resume_font);
  margin-left: 0.16rem;
}
.lf_alert_box .lf_btn_select_video_s_tit_2 {
  font-size: 0.14rem;
  font-weight: 400;
  color: var(--Color_section2_font);
  display: flex;
  align-items: center;
  margin-right: 0.14rem;
}
.lf_alert_box .lf_btn_select_video_s_tit_2 img {
  transform: rotate(180deg);
  margin-left: 0.06rem;
  /* width: 0.22rem;
  height: 0.22rem; */
  width: 20px;
  height: 20px;
}
.ysj_pointer {
  cursor: pointer;
}

.lf_main_box_mid_video_fullscreen {
  position: fixed;
  z-index: 10000;
  height: 100vh;
  top: 0px;
}
#iframeScaleDiv {
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1000099090;
  background: #000000;
}
</style>
