<template>
  <div class="home" style="width: 100%">
    <Header />

    <div style="width: 100%; display: flex">
      <div style="">
        <Menu @childChangeMenuItem="changeMenuItem" />
      </div>

      <div style="flex: 1">
        <Right :currentMenuItem="currentMenuItem"></Right>
      </div>
    </div>

    <div style="width: 100%">
      <Copyright />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { ElButton } from "element-plus";
import Test from "@/components/Test.vue";
import Header from "./Header.vue";
import Menu from "./Menu.vue";
import Right from "./Right.vue";

import Copyright from "@/components/Copyright.vue";

// import Test from ""
export default {
  name: "Home",
  components: { ElButton, Test, Header, Menu, Right, Copyright },
  props: {
    msg: String,
  },

  setup() {
    const currentMenuItem = ref("home");

    const changeMenuItem = (e) => {
      currentMenuItem.value = e;
    };

    return {
      currentMenuItem,
      changeMenuItem,
    };
  },
};

computed(() => {
  return {
    count: function () {
      count.value = "aaa" + count.value;
    },
  };
});
</script>
