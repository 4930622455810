<!--
 * @Author: yushuangji
 * @Date: 2022-03-06 21:07:56
 * @Description: 
-->
<template>
  <!-- v-loading="state.loading"  element-loading-background="rgba(0,0,0,0)" -->

  <div v-loading="loading" ref="refFirst">
    <el-row>
      <!-- PC版 -->
      <el-col :xs="24" :sm="24" :xl="24" class="pc_box" v-if="!isMobile">
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-top: 0.14rem;
            margin-left: 0.3rem;
          "
        >
          <div>
            <img :src="imgUrlPrefix1 + 'firstlogo.png'" style="width: 2rem" />
          </div>

          <div style="display: flex; align-items: start; margin-right: 60px">
            <Welcome fontSize="18px" />

            <img
              class="ysj_pointer"
              v-if="configValue.login_enabled == '1' && !loginStatus"
              :src="imgUrlPrefix1 + 'login.png'"
              alt=""
              style="width: 0.25rem; margin-left: 20px"
            />

            <img
              class="ysj_pointer"
              v-if="configValue.login_enabled == '1' && loginStatus"
              @click="quit"
              :src="imgUrlPrefix1 + 'exit.png'"
              alt=""
              style="width: 80.14px; height: 28.78px; margin-left: 20px"
            />
          </div>
        </div>

        <div
          class="lf_main_box"
          style="
            padding: 50px;
            margin-bottom: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;
          "
        >
          <div
            style="
              width: 50%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
            "
          >
            <template v-for="(item, index) in dataList" :key="index">
              <div
                class="imgSpan"
                v-if="index % 2 == 0"
                @click="goSection2(item)"
              >
                <img :src="item.logo" alt="" />
                <span class="itemtitle">
                  {{ item.title }}
                </span>
              </div>
            </template>
          </div>

          <div style="margin: 0 60px">
            <div style="width: 3rem; height: 60vh">
              <!-- <img
                :src="imgUrlPrefix1 + 'center.png'"
                alt=""
                style="width: 3rem"
              /> -->
            </div>
            <div style="display: flex; justify-content: flex-end">
              <!-- <img
                :src="imgUrlPrefix1 + 'code.png'"
                alt=""
                style="width: 0.25rem; height: 0.3rem"
              /> -->
            </div>
          </div>

          <div
            style="
              width: 50%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
            "
          >
            <template v-for="(item, index) in dataList" :key="index">
              <div
                class="imgSpan"
                v-if="index % 2 != 0"
                @click="goSection2(item)"
              >
                <img :src="item.logo" alt="" style="" />
                <span class="itemtitle">
                  {{ item.title }}
                </span>
              </div>
            </template>
          </div>
        </div>
      </el-col>

      <!-- 手机版 -->
      <el-col :xs="24" :sm="24" :xl="24" class="" v-if="isMobile">
        <div>
          <div
            style="
              margin-top: 10px;
              width: 100%;
              z-index: 999;
              position: absolute;
            "
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span style="margin-left: 20px; color: rgb(191, 215, 215)">
                <Welcome fontSize="18px" />
              </span>

              <img
                class="ysj_pointer"
                v-if="configValue.login_enabled == '1' && !loginStatus"
                :src="imgUrlPrefix1 + 'login.png'"
                alt=""
                style="width: 0.25rem; margin-left: 20px; margin-right: 20px"
              />

              <img
                class="ysj_pointer"
                @click="quit"
                v-if="configValue.login_enabled == '1' && loginStatus"
                :src="imgUrlPrefix1 + 'exit_phone.png'"
                alt=""
                style="width: 0.25rem; margin-left: 20px; margin-right: 20px"
              />
            </div>
          </div>

          <div class="lf_main_box_phone" style="">
            <div>
              <img :src="imgUrlPrefix1 + 'header.jpg'" style="width: 100%" />
            </div>
            <div
              
            >
            

              <div style=""></div>

              <div style="width: 100%; ">
                <!-- 小于8个的情况 -->
                <div v-if="dataList.length <= 8" style="margin-top:20px;">
                  <template v-for="(item, index) in dataList" :key="index">
                    <div
                      @click="goSection2(item)"
                      class="1"
                      style="
                        align-items: center;
                        display: flex;
                        margin-bottom: 20px;
                        padding-bottom: 20px;
                        width: 100%;
                        justify-content: space-between;
                        border-bottom: solid 1px #e5ebf7;
                      "
                    >
                      <div style="border: solid 0px red; width: 5px"></div>
                      <div>
                        <img
                          :src="item.logo"
                          alt=""
                          style="width: 0.5rem; height: 0.5rem"
                        />
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          width: 50%;
                        "
                      >
                        <span
                          class=""
                          style="
                            font-size: 18px;
                            margin-right: 20px;
                            font-weight: bold;
                          "
                          >{{ item.title }}</span
                        >
                      </div>
                      <div style="border: solid 0px red; width: 5px"></div>
                    </div>
                  </template>
                </div>

                <!-- 多于8个的情况 -->
                <div
                  v-if="dataList.length > 8"
                  style="
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                  "
                >
                  <template v-for="(item, index) in dataList" :key="index">
                    <div class="imgSpanPhone" @click="goSection2(item)">
                      <img :src="item.logo" alt="" />
                      <span class="itemtitle">
                        {{ item.title }}
                      </span>
                    </div>
                    <div
                      v-if="(index + 1) % 3 == 0 && index != 0"
                      style="
                        width: 100%;
                        height: 2px;
                        background-color: #e5ebf7;
                      "
                    ></div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <Fotter
      :loadedData="loadedData"
      :isMobile="isMobile"
      :showBackgroundColor="true"
    ></Fotter>
  </div>
</template>

<script>
import Fotter from "../components/Fotter.vue";
import { requestHttp } from "../network/request";
import { errorMessage } from "../utils/message";
import { onMounted, reactive, toRefs, ref } from "vue";
import { useRouter } from "vue-router";
import {
  userQuit,
  getImgUrlPrefix,
  checkMobile,
  getCommonConfigValue,
  checkWx,
} from "@/utils/commonFunc";
import Welcome from "@/components/Welcome.vue";

export default {
  name: "Home",
  components: { Fotter, Welcome },
  props: {
    msg: String,
  },
  setup() {
    const imgUrlPrefix1 = getImgUrlPrefix();
    const configValue = getCommonConfigValue();
    const refFirst = ref();

    const state = reactive({
      loadedData: false, //加载成功
      loading: false,
      dataList: [],
      loginStatus: false,
      isMobile: false,
      configValue: configValue,
    });
    const router = new useRouter();

    const getSections1 = () => {
      let tmpUrl = "getSection1";
      let tmpParams = {};

      state.loading = true;

      requestHttp(
        {
          headerParams: tmpParams,
          url: tmpUrl,
        },
        function (res) {
          state.loading = false;

          let tmpData = res.data.data;
          if (tmpData?.list.length > 0) {
            state.dataList = tmpData.list;
          }

          setTimeout(() => {
            state.loadedData = true;
          }, 1500);

          if (tmpData?.list.length == 1) {
            const tmprouter = router;
            tmprouter.push({
              path: "/home",
              query: {
                sections1Id: tmpData.list[0].id,
              },
            });
          } else if (tmpData?.list.length > 1) {
            //
            //统计first页面的访问次数
            setVisitCount();
          }
        },
        function (errData) {
          //处理错误信息
          errorMessage(errData.msg);
          state.loading = false;
        }
      );
    };

    const goSection2 = (item) => {
      const tmprouter = router;

      //判断是否为外部链接
      if (item.type == "link") {
        window.open(item.link_url);
      } else {
        tmprouter.push({
          path: "/home",
          query: {
            sections1Id: item.id,
          },
        });
      }
    };

    const quit = () => {
      userQuit();
      router.replace("/Login");
    };

    const setVisitCount = () => {
      const tmpUrl = "setCount";
      requestHttp(
        {
          headerParams: {
            type: "first",
          },
          url: tmpUrl,
        },
        function () {}
      );
    };

    //检查横屏
    const checkScreenDirection = () => {
      window.addEventListener(
        "onorientationchange" in window ? "orientationchange" : "resize",
        function () {
          if (!checkWx()) {
            if (window.orientation === 180 || window.orientation === 0) {
              window.location.reload();
            }
            if (window.orientation === 90 || window.orientation === -90) {
              window.location.reload();
            }
          }
        },
        false
      );
    };

    onMounted(() => {
      getSections1();
      if (localStorage.getItem("sshuuid")) {
        state.loginStatus = true;
      } else {
        state.loginStatus = false;
      }

      setTimeout(() => {
        refFirst.value.style.setProperty(
          "--Img_backimg",
          `url(${imgUrlPrefix1}/center.png)`
        );
      }, 1000);
      state.isMobile = checkMobile();

      checkScreenDirection();
    });

    return {
      ...toRefs(state),
      refFirst,

      goSection2,
      quit,
      imgUrlPrefix1,
    };
  },
};
</script>

<style scoped>
.imgSpan {
  margin-right: 20px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.imgSpan img {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 10px;
  /* border: solid 2px #b4d6d4; */
  cursor: pointer;
}

.imgSpan img:hover {
  transform: scale(1.05);
}

.imgSpanPhone {
  margin: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.imgSpanPhone img {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 10px;

  cursor: pointer;
}
.pc_box {
  background: var(--Img_backimg) no-repeat;
  /* background-size: 6rem; */
  background-position: center;
  background-size: cover;
}
.itemtitle {
  width: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
</style>
