<template>
  <div class="home" style="width: 100%" v-loading="loading">
    <div>
      <el-card class="box-card" shadow="hover">
        <div style="padding-top: 15px; margin-top: 10px">
          <UploadFile
            @emit_SelectedFiles="getUploadFIles"
            :showUploadLog="!trueValue"
            :limit="1"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from "vue";
import { errorMessage, successMessage } from "@/utils/message";
import { requestHttp } from "@/network/request";
import UploadFile from "@/components/uploadFile/UploadFile.vue";

export default {
  name: "Contents",
  components: { UploadFile },
  props: {
    sections1Id: [Number, String],
    sections2Id: [Number, String],
  },

  setup() {
    const activeName = ref("first");

    const state = reactive({
      loading: false,
      trueValue: true,
      formData: {
        selectFileData: {},
        lessionType: "others", //others，pictures
      },
    });

    //------------------------------------------------------------------------------------------------------------
    // 方法 start
    //------------------------------------------------------------------------------------------------------------
    const getUploadFIles = (e) => {
      if (e.length > 0) {
        state.formData.selectFileData = e[0].raw;
      }
    };
    //------------------------------------------------------------------------------------------------------------
    // 方法 end
    //------------------------------------------------------------------------------------------------------------

    //------------------------------------------------------------------------------------------------------------
    // 事件 start
    //------------------------------------------------------------------------------------------------------------

    //------------------------------------------------------------------------------------------------------------
    // 事件 end
    //------------------------------------------------------------------------------------------------------------

    onMounted(() => {});
    return {
      ...toRefs(state),
      activeName,
      getUploadFIles,
    };
  },
};
</script>

<style scoped>
div,
span,
p {
  font-size: 15px !important;
}

.cell {
  font-size: 15px !important;
}

.el-input {
  max-width: 200px;
}

.actualname {
  color: rgb(78, 175, 231);
}
</style>
