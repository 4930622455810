<!--
 * @Author: yushuangji
 * @Date: 2022-03-06 19:34:03
 * @Description: 
-->
<template>
  <div class="lf_header_box" ref="refContent">
    <div :span="24" class="" v-if="!isMobile">
      <div class="lf_main_box_top" :style="{ marginLeft: marginLeft }">
        <div
          v-for="item in dataList"
          :key="item"
          @click="goSection2(item)"
          style="cursor: pointer"
          :style="{ width: itemWidth }"
        >
          <span
            style="font-size: 0.1rem"
            class="ysj_pointer"
            :class="{ lf_select: item.id == routerData.sections2Id }"
          >
            {{ item.title }}
          </span>
        </div>
      </div>
    </div>

    <!-- 手机版 -->
    <div
      :xs="24"
      class=""
      style="display: flex; align-items: center"
      v-if="isMobile"
    ></div>
  </div>
</template>

<script>
import { requestHttp } from "@/network/request";
import { errorMessage } from "@/utils/message";
import { onMounted, reactive, toRefs, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { getSections1Colors } from "@/utils/commonFunc";

export default {
  name: "Header",
  props: {
    color: {
      type: String,
      default: "white", //white,c67f45
    },
    marginLeft: {
      type: String,
      default: "", //0.3rem
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();
    const routerData = router.currentRoute.value.query;
    const refContent = ref();

    const state = reactive({
      dataList: [],
      routerData,
      itemWidth: "0.3rem",
    });

    const getSection2 = () => {
      //发送验证码
      requestHttp(
        {
          headerParams: {
            parent_id: routerData.sections1Id,
          },
          url: "/getSection2",
          // url: "/getLessions",
        },
        function (dd) {
          let tmpData = dd.data.data;
          state.dataList = tmpData.list;
          setItemWidth();
        },
        function (errData) {
          //处理错误信息
          errorMessage(errData.msg);
        }
      );
    };

    const goSection2 = (item) => {
      let tmpPath = item.type;
      if (item.type == "h5") {
        tmpPath = "h5new";
      }
      if (item.type == "pictures") {
        tmpPath = "picture";
      }

      router.push({
        path: tmpPath,
        query: {
          sections1Id: item.sections1_id,
          sections2Id: item.id,
        },
      });
    };

    const setColors = () => {
      const colors = getSections1Colors();
      refContent.value.style.setProperty(
        "--Color_section2_font",
        colors.color_section2_font
      );
      refContent.value.style.setProperty(
        "--Color_play_resume_font",
        colors.color_play_resume_font
      );
    };
    const setItemWidth = () => {
      let maxWidth = 0;
      state.dataList.forEach((item) => {
        if (maxWidth < item.title.length) {
          maxWidth = item.title.length;

          if (item.title.length > 5) {
            state.itemWidth = "0.6rem";
          }
          if (item.title.length >= 7) {
            state.itemWidth = "0.8rem";
          }
        }
      });
    };

    onMounted(() => {
      getSection2();
      setColors();
    });
    watch(
      () => router.currentRoute.value.fullPath,
      () => {
        window.history.go(0);
      }
    );

    return { ...toRefs(state), goSection2, refContent, routerData };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.lf_main_box_top .lf_select {
  border-bottom: 2px solid var(--Color_section2_font);
  color: var(--Color_section2_font);
}

.lf_main_box_top {
  display: flex;
  align-items: center;
  /* margin: 0.1rem 0.3rem; */
  margin: 44px 0px 10px;
  justify-content: center;
}

.lf_main_box_top div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Color_play_resume_font);
  /* font-size: 0.2rem; */
  /* width: 0.25rem; */
  word-break: keep-all;
  margin: 0 0.13rem;
}
</style>