<!--
 * @Author: yushuangji
 * @Date: 2022-02-16 21:28:22
 * @Description: 
-->
<template>
  <div
    style="
      border-bottom: solid 1px #e6e6e6;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
  >
    <div>
      <img src="@/assets/images/icons/logo.jpg" style="width: 100px" alt="" />
    </div>

    <div>
      <el-dropdown>
        <img src="@/assets/images/icons/user.png" style="width: 30px" alt="" />
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="quit">退出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>


<script >
import { computed, onMounted, ref, defineComponent, watch } from "vue";
import { ElButton } from "element-plus";
import { useRouter } from "vue-router";
export default {
  components: { ElButton },
  props: {},

  setup() {
    const router = new useRouter();

    const quit = () => {
      sessionStorage.removeItem("admintype");
      sessionStorage.removeItem("adminuuid");
      router.push("adminLogin");
    };

    onMounted(() => {
      console.log("-----");
    });

    watch(
      () => router.currentRoute.value.fullPath,
      () => {
        window.history.go(0);
      }
    );

    return {
      quit,
    };
  },
};
</script>
