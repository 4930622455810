/**
 * 
 * 一些工具类的通用函数
 * 
 */

import { requestHttp } from "@/network/request";
import router from '../router/index'

//
/**
 * @description 判断是否为手机号的正则表达式
 * @param telephone 
 * @returns  true | false
 * @author yushuangji
 * @date 2021/12/16
 */
function common_CheckMobileTel(telephone) {

    const myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    if (!myreg.test(telephone)) {
        return false;
    } else {
        return true;
    }
}



// 
/**
 * @description 判断是否为固定电话正则
 * @param telephone 
 * @returns  true | false
 * @author yushuangji
 * @date 2021/12/16
 */
function common_CheckLandline(telephone) {
    telephone = telephone.replace(/[^-|\d]/g, '');
    return /^((\+86)|(86))?(1)\d{10}$/.test(telephone) || /^0[0-9-]{10,13}$/.test(telephone);

}

/**
 * 日期格式
 * @param dateObj 
 * @returns string 2021-02-05
 */
function common_formatDate(dateObj) {

    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;//js从0开始取 
    const date = dateObj.getDate();

    let monthStr = month.toString();
    let dateStr = date.toString();
    if (month < 10) {
        monthStr = "0" + month.toString();
    }
    if (date < 10) {
        dateStr = "0" + date.toString();
    }


    const time = year + "-" + monthStr + "-" + dateStr; //2009-06-12 17:18:05
    return time;
}

/**
 * 日期格式2
 * @param dateObj 
 * @returns string 2021年2月15日 15:20
 */
function common_formatDate1(dateObj) {

    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;//js从0开始取 
    const date = dateObj.getDate();
    const hour = dateObj.getHours();
    const minute = dateObj.getMinutes();



    const time = year + "年" + month + "月" + date + ' ' + hour + ':' + minute; //2009-06-12 17:18    
    return time;
}


/**
 * 日期格式2
 * @param dateObj 
 * @returns string 2021年2月15日 15:20
 */
function common_formatDate3(dateObj) {

    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;//js从0开始取 
    const date = dateObj.getDate();



    const time = year + "年" + month + "月" + date + '日 '; //2009-06-12    
    return time;
}

/**
 * 判断是否为空
 * @param   str 
 * @returns boolean  , true:为空，false:不为空
 */
function common_isNull(str) {
    if (str === '' || str == undefined || str == '0' || str == 0 || str.length == 0) {
        return true;
    } else {
        return false;
    }
}

function getLocalStorageCacheItem(key) {

    return localStorage.getItem(key);
}
function removeLocalStorageCacheItem(key) {
    localStorage.removeItem(key);
}
function userQuit() {
    removeLocalStorageCacheItem('sshuuid');
    removeLocalStorageCacheItem('sshname');
}


function makeUrl(dataObj, searchType = '', searchValue = '') {
    let tmpData1 = dataObj;

    let tmpQueryParams = {};
    tmpQueryParams["sections1Id"] = tmpData1.sections1_id;
    tmpQueryParams["sections2Id"] = tmpData1.sections2_id;
    tmpQueryParams["lessionId"] = tmpData1.id;
    tmpQueryParams["type"] = tmpData1.type;
    tmpQueryParams["searchType"] = searchType;
    tmpQueryParams["searchValue"] = searchValue;

    let tmpPath = "";
    if (tmpData1.type == "h5") {
        tmpPath = "/h5new";
    }
    if (tmpData1.type == "pictures") {
        tmpPath = "/picture";
    }
    if (tmpData1.type == "video") {
        tmpPath = "/video";
    }

    return {
        path: tmpPath,
        query: tmpQueryParams,
    }
}
const getLessionsWhere = (router) => {

    let path = router.path;
    let tmpQueryParams = router.query;
    let that = this;

    let tmpPath = "getLessions";
    let tmpPostData = {}

    //走搜索或者索引
    if (tmpQueryParams?.searchType && tmpQueryParams?.searchValue) {
        if (tmpQueryParams?.searchType == 'indexWord') {
            //索引 
            tmpPath = "searchLessionsByWordIndex";
            tmpPostData['word'] = tmpQueryParams?.searchValue
        }
        if (tmpQueryParams?.searchType == 'keyWord') {
            //关键字
            tmpPath = "getLessions";
            tmpPostData['keyword'] = tmpQueryParams?.searchValue
        }

        if (tmpQueryParams?.type == 'pictures') {
            tmpPostData['type'] = 'pictures';
        }
    } else {
        tmpPostData['sections2_id'] = tmpQueryParams?.sections2Id;
    }

    tmpPostData['sections1id'] = tmpQueryParams?.sections1Id;


    return new Promise((re, rj) => {
        requestHttp(
            {
                headerParams: tmpPostData,
                url: tmpPath,
            },
            function (dd) {
                let tmpData = dd.data.data;
                re(dd.data);

            },
            function (errData) {
                //处理错误信息
                rj(errData);
            }
        );
    })
}

const getImgUrlPrefix = () => {
    // debugger;

    // const tmpDomain = 'http://2022.atleer.com';
    const tmpDomain = getDomain();
    const imgUrlPrefix = tmpDomain + '/api/public/static/images/';
    // const returnVal=await getCommonConfig();
    // debugger;


    const a = router.currentRoute.value.query;
    console.log('a=', a);
    return imgUrlPrefix + (a?.sections1Id ? a?.sections1Id : 'home') + '/';
    // return imgUrlPrefix + '/';
}
const getCommonConfig = () => {
    const tmpPostData = {};
    const tmpPath = "getSection1Colors";

    const config = sessionStorage.getItem('commonconfig');
    if (config) {
        return new Promise((re) => {
            re(JSON.parse(config));
        })
    } else {

        return new Promise((re, rj) => {
            requestHttp(
                {
                    ignoreCheckUserid: true,
                    headerParams: tmpPostData,
                    url: tmpPath,
                },
                function (dd) {
                    let tmpData = dd.data.data.config[0];
                    re(tmpData);

                },
                function (errData) {
                    //处理错误信息
                    rj(errData);
                }
            );
        })
    }
}
const getCommonConfigValue = async () => {
    const v = await getCommonConfig();
    return v;
}

//判断是否为手机浏览器
const checkMobile = () => {
    // let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    // return flag;

    // window.fullHeight = document.documentElement.clientHeight;
    // window.fullWidth = document.documentElement.clientWidth;
    // if (window.fullHeight > window.fullWidth) {
    //     return true;
    // } else {
    //     return null;
    // }

    if (window.orientation === 180 || window.orientation === 0) {
        return true;
    }
    if (window.orientation === 90 || window.orientation === -90) {
        return false;
    }
}

const getSections1Colors = () => {


    const a = router.currentRoute.value.query;
    const sections1_id = a?.sections1Id || 0;

    const tmp = JSON.parse(sessionStorage.getItem('sections1Colors'));

    if (!tmp) {
        return []
    }
    const tmpData = tmp.find((item) => {
        return item.sections1_id == sections1_id
    })
    return tmpData;

}

//增加播放量
const setPlayCount = (id, type) => {
    let tmpUrl = "setCount";
    let tmpParams = {
        type: type,
        id: id,
    };

    requestHttp(
        {
            headerParams: tmpParams,
            url: tmpUrl,
        }, () => {

        }, () => {

        }

    );
}
//取域名
const getDomain = () => {
    let domain = 'https://leer.atleer.com/';
    const location = window.location;
    domain = `${location.protocol}//${location.host}`;

    // const commonConfig = await getCommonConfig();
    // domain = commonConfig.hostname;


    return domain;
}

const checkWx = () => {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf("micromessenger") != -1;
    if (isWeixin) {
        undefined;
        return true;
    } else {
        undefined;
        return false;
    }
};


export { getCommonConfigValue, getCommonConfig, checkWx, getDomain, setPlayCount, getSections1Colors, checkMobile, getImgUrlPrefix, getLessionsWhere, makeUrl, userQuit, removeLocalStorageCacheItem, getLocalStorageCacheItem, common_isNull, common_CheckMobileTel, common_CheckLandline, common_formatDate, common_formatDate1, common_formatDate3 };